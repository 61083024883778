<template>
  <div>
    <AnfangsBild
      bild="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anfangsbilder%2FRouten_Anfangsbild.jpg?alt=media&token=893b3a1b-089b-4bdf-9eab-554da6d283f0"
      textErsteZeile="Routen zum"
      textZweiteZeile="Downloaden"
    />
    <!--BildmitText-->
    <BildmitText
      :bild="imageTextComponent.imageSrc"
      :titel="imageTextComponent.titel"
      :text="imageTextComponent.text"
      :align="imageTextComponent.align"
      :flipped="imageTextComponent.flipped"
    />
    <v-container fluid style="max-width: 1365px" class="mt-lg-16 mt-8 px-10"
      ><p class="text-lg-h6 font-weight-bold pl-3">
        Downloadliste der Routen in .gpx oder .gdb
      </p>
      <v-data-table
        :headers="headers"
        :items="orte"
        :search="search"
        :header-props="headerProps"
        :footer-props="footerProps"
        :items-per-page="-1"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Suche"
            class="mx-4"
            single-line
            hide-details
          ></v-text-field>
        </template>
        <template v-slot:[`item.download`]="{ item }">
          <a :href="item.download" download>
            <font-awesome-icon
              icon="fa-solid fa-download"
              color="rgb(48, 66, 83, 1)"
              class="ml-5"
          /></a>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import AnfangsBild from "@/components/AnfangsBild.vue";
import BildmitText from "@/components/BildmitText.vue";
export default {
  name: "RoutenView",
  metaInfo: {
    title: "Routen",
  },
  data: () => ({
    imageTextComponent: {
      titel: "Routen zum Downloaden",
      text: "Hallo Biker, hier stellen wir Euch unsere Motorradrouten vor. Die Motorradrouten wurden mit Garmin BaseCamp bearbeitet und auch abgefahren. Vielleicht sind einige Routen dabei, die in Eurer Planung mit einfließen können. Schaut doch mal rein... Kleiner Tipp: Ihr könnt die Routen mit dem GPX-Viewer aus dem Apple- oder Play-Store anschauen.",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FRouten_zum_Downloaden.jpg?alt=media&token=616fec36-22ef-41e0-910b-c97080505341",
      align: "start",
      flipped: false,
    },
    headerProps: {
      sortByText: "Sortieren nach",
    },
    footerProps: {
      itemsPerPageText: "Reihen pro Seite",
    },
    search: "",
    orte: [
      {
        ort: "Dolomiten",
        datum: "2005-06-25",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2005.06.25_Italien.gdb?alt=media&token=eb1ac5f8-58dd-463e-accc-0844ee2cee3c",
      },
      {
        ort: "Dolomiten",
        datum: "2006-08-06",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2006.08.06_Italien.gdb?alt=media&token=f41cb1fb-37e6-4118-b0bc-e094262feccd",
      },
      {
        ort: "Biggesee",
        datum: "2006-08-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2006.08.12_Biggesee.gpx?alt=media&token=b270ee7a-1acf-4e45-8646-e933a1bcb1ca",
      },
      {
        ort: "Dolomiten",
        datum: "2008-06-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2008.06.13_Italien.gdb?alt=media&token=d8ea0ca8-b625-404d-8d61-8b21f0f45816",
      },
      {
        ort: "Mosel",
        datum: "2009-06-24",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2009.06.24_Mosel.gpx?alt=media&token=14d4f60c-62cc-4100-86d8-a77cbe79740b",
      },
      {
        ort: "Sauerland",
        datum: "2010-06-24",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2010.06.24_Sauerland.gpx?alt=media&token=1f60fbf8-f55b-4189-b8c7-30661fcef3d8",
      },
      {
        ort: "Fulda Weser",
        datum: "2010-07-14",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2010.07.14_Fulda_Weser.gpx?alt=media&token=49763bdb-9d29-49f1-af40-0bd737b89437",
      },
      {
        ort: "Dolomiten",
        datum: "2010-09-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2010.09.12_Italien.gpx?alt=media&token=53bf1f3f-8635-4892-9c2f-b42813a0e54e",
      },
      {
        ort: "Dolomiten",
        datum: "2010-09-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2010.09.16_Italien.gpx?alt=media&token=a768f74d-7caf-43c5-822d-801652a77024",
      },
      {
        ort: "Motodrom Hagen",
        datum: "2011-05-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.05.15_Motodrom%20Hagen.gpx?alt=media&token=3cf4c427-b928-41b4-a135-b12f1015b13e",
      },
      {
        ort: "XXXL Schnitzelkönig",
        datum: "2011-06-02",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.06.02_XXXL_Schnitzelk%C3%B6nig.gpx?alt=media&token=1f7e9e95-e3d0-4d84-af3d-33bfa79ecc5f",
      },
      {
        ort: "Holzhau Sachsen",
        datum: "2011-06-29",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.06.29_Holzhau_Sachsen.gpx?alt=media&token=1fdf16e6-0f2f-488f-8ac3-53861d1c8fb7",
      },
      {
        ort: "Tagestouren NRW",
        datum: "2011-07-14",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.07.14_Tagestouren_NRW.gpx?alt=media&token=316fd88d-12a4-42e0-9895-800bcf1c7745",
      },
      {
        ort: "Biggesee",
        datum: "2011-08-06",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.08.06_Biggesee.gpx?alt=media&token=20b047cc-c90d-4ced-8db8-26c5a1de924a",
      },
      {
        ort: "Dolomiten",
        datum: "2011-09-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.09.15_Italien.gdb?alt=media&token=176a773f-a885-40a9-99f2-13e7a9abf2cf",
      },
      {
        ort: "Sauerland",
        datum: "2011-10-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2011.10.16_Sauerland.gpx?alt=media&token=eaae4509-252e-4cee-9db4-82faa9f17451",
      },
      {
        ort: "Bremerhafen",
        datum: "2012-05-19",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2012.05.19_Bremerhafen.gpx?alt=media&token=0eecd440-6efb-4f59-8ea1-e513cd73a5c0",
      },
      {
        ort: "Hunsrück",
        datum: "2012-06-29",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2012.06.29_Hunsr%C3%BCck.gpx?alt=media&token=a06326aa-7ac5-4cbe-8381-ace70397c29a",
      },
      {
        ort: "Weser Schlemmertour",
        datum: "2012-07-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2012.07.22_Weser_Schlemmertour.gpx?alt=media&token=4e33c95a-82b1-4cce-8cf1-ff524414c125",
      },
      {
        ort: "Harz Ring",
        datum: "2012-08-23",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2012.08.23_Harz_Ring.gpx?alt=media&token=79120ef0-a321-439d-99a6-89c2663d33ce",
      },
      {
        ort: "Dolomiten",
        datum: "2012-09-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2012.09.16_Italien.gdb?alt=media&token=ed39ff10-d263-4202-b8a0-8cb5b39afead",
      },
      {
        ort: "Wilhemshaven",
        datum: "2013-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2013.05.01_Wilhemshaven.gpx?alt=media&token=c9ef01f9-c19c-462f-a4e8-f3957546cf3b",
      },
      {
        ort: "Knusberhäuschen",
        datum: "2013-07-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2013.07.13_Knusberh%C3%A4uschen.gpx?alt=media&token=e41ee2ff-5ff0-431d-b6ee-63140a619c31",
      },
      {
        ort: "Dolomiten",
        datum: "2013-09-19",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2013.09.19_Italien.gdb?alt=media&token=fd7a76f0-dfc6-40a3-893a-9b77c9a8fdfa",
      },
      {
        ort: "Abschlusstour",
        datum: "2013-10-03",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2013.10.03_Abschlusstour.gpx?alt=media&token=da4f4020-7f32-4b10-beb6-4c558a8e490a",
      },
      {
        ort: "Tagestour",
        datum: "2014-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.05.01_Tagestour.gpx?alt=media&token=62396d13-2301-47b2-9d8a-b4936ba402ea",
      },
      {
        ort: "Biggesee",
        datum: "2014-05-31",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.05.31_Biggesee.gpx?alt=media&token=9efb148b-5cfe-44e0-89d0-97e7de667161",
      },
      {
        ort: "Sauerland",
        datum: "2014-06-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.06.22_Sauerland.gpx?alt=media&token=2be11d44-b850-4517-b51d-9213cb6f5f70",
      },
      {
        ort: "Ferienpark Retgendorf",
        datum: "2014-06-24",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.06.24_Ferienpark_Retgendorf.gpx?alt=media&token=1904c48f-443f-49ba-8f6a-4d1eef9da57f",
      },
      {
        ort: "Tagestour Bremerhafen",
        datum: "2014-07-19",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.07.19_Tagestour_Bremerhafen.gpx?alt=media&token=8d2e07b5-8e46-4d7a-b34a-238858fee6cd",
      },
      {
        ort: "Wurmberg",
        datum: "2014-08-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.08.16_Wurmberg.gpx?alt=media&token=41cbf49d-53ad-4a42-9b19-391a087da3e2",
      },
      {
        ort: "Dolomiten",
        datum: "2014-09-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.09.22_Italien.gdb?alt=media&token=5bfa09b0-3d79-4af2-ac42-b66ba385e392",
      },
      {
        ort: "Enschede",
        datum: "2014-10-11",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2014.10.11_Enschede.gpx?alt=media&token=4ccc0f95-6ad1-40a7-9799-049235be4cc3",
      },
      {
        ort: "Saison Start",
        datum: "2015-05-09",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.05.09_Saison_Start.gpx?alt=media&token=8e479b8a-0ec2-4ead-9a03-bdc2b8d4783b",
      },
      {
        ort: "Spagelhof Winkelmann",
        datum: "2015-05-14",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.05.14_Spagelhof_Winkelmann.gpx?alt=media&token=551c4c2a-57b2-4aa8-97fc-cd9fb8e3f057",
      },
      {
        ort: "Bodenwerder",
        datum: "2015-05-31",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.05.31_Bodenwerder.gpx?alt=media&token=cc4fee44-c287-4172-ade6-242af10dff27",
      },
      {
        ort: "Wernigerode",
        datum: "2015-06-20",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.06.20_Wernigerode.gpx?alt=media&token=d73c4889-1de9-4682-8d74-4ae44c34c4d2",
      },
      {
        ort: "Limburg an der Lahn",
        datum: "2015-07-20",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.07.20_Limburg_an_der_Lahn.gpx?alt=media&token=d3d9d7f6-7f28-4804-80dd-d9bf89988e0d",
      },
      {
        ort: "Sauerland Tour",
        datum: "2015-07-26",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015_Sauerland_Tour.gpx?alt=media&token=528a0d6e-576e-4c67-ab7d-4851f08d3a52",
      },
      {
        ort: "Bad Harzburg",
        datum: "2015-08-20",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.08.15_Bad_Harzburg.gpx?alt=media&token=e865f01a-cd12-464c-83a7-0781dee51e43",
      },
      {
        ort: "Enschede",
        datum: "2015-09-03",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.09.03_Enschede.gpx?alt=media&token=10da6cff-afaa-4ac3-b203-86094ade29cd",
      },
      {
        ort: "Dolomiten",
        datum: "2015-09-17",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2015.09.17_Italien.gpx?alt=media&token=7d1530cd-8889-44c1-8061-5d8abafa5caa",
      },
      {
        ort: "Saison Start Hameln Holzminden",
        datum: "2016-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.05.01_Saison_Start_Hameln_Holzminden.gpx.gpx?alt=media&token=5be37881-904e-436b-bedd-56fdae5f7c32",
      },
      {
        ort: "Knusperhäuschen Bad Wildungen",
        datum: "2016-05-04",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.05.04_Knusperh%C3%A4uschen_Bad_Wildungen.gpx.gpx?alt=media&token=ac9a5563-5640-4b68-97a9-f67f312be6a3",
      },
      {
        ort: "Spagelhof Bohmte",
        datum: "2016-05-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.05.16_Spagelhof_Bohmte.gpx?alt=media&token=910f7e64-931c-4a57-b430-c730c7d42ca5",
      },
      {
        ort: "Motodrom Hagen",
        datum: "2016-05-28",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.05.28_Motodrom_Hagen.gpx?alt=media&token=38f61932-b77f-4b92-a2b7-5453f01727f6",
      },
      {
        ort: "Mosel",
        datum: "2016-06-30",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.06.30_Mosel.gpx?alt=media&token=0c410b51-3f57-4f0e-9a74-394552be1f24",
      },
      {
        ort: "Knusberhäuschen",
        datum: "2016-07-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.07.16_Knusberh%C3%A4uschen.gpx?alt=media&token=6bbc1d98-2ead-470f-9265-f52abbb31ea7",
      },
      {
        ort: "Bremerhaus Hin Rück",
        datum: "2016-07-17",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.07.17_Bremerhaus_Hin_R%C3%BCck.gpx?alt=media&token=d7086bb9-e648-4c76-8a9f-c11b3a26ae06",
      },
      {
        ort: "Günters Kurve",
        datum: "2016-07-27",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.07.27_G%C3%BCnters_Kurve.gpx?alt=media&token=ce86fb9a-a86b-4c51-8a4d-88ce7f809af0",
      },
      {
        ort: "Dolomiten",
        datum: "2016-09-10",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.09.10_Italien.gpx?alt=media&token=ea602dbc-8d47-4859-9391-ebf9771a45b7",
      },
      {
        ort: "Mühlenstrasse",
        datum: "2016-10-08",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2016.10.08_M%C3%BChlenstrasse.gpx?alt=media&token=ead8dac9-8067-42e7-8554-1aa2f9970b53",
      },
      {
        ort: "Saisonstart Steinhuder Meer",
        datum: "2017-05-07",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.05.07_Saisonstart_Steinhuder_Meer.gpx?alt=media&token=da1e3a33-8a14-42f6-82a2-00f43d5656db",
      },
      {
        ort: "Haltern am See",
        datum: "2017-05-07",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.05.07_Haltern_am_See.gpx?alt=media&token=0b97babc-1608-476f-a020-068b29314c64",
      },
      {
        ort: "Sauerland",
        datum: "2017-06-10",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.06.10_Sauerland.gpx?alt=media&token=ea248060-5b99-42f4-a00c-8e5ae5fe63a0",
      },
      {
        ort: "Günters Kurve Köterberg Bad Meinberg",
        datum: "2017-06-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.06.15_G%C3%BCnters_Kurve_K%C3%B6terberg_Bad_Meinberg.gpx?alt=media&token=243710b9-4385-43e8-8ff9-24610219ab94",
      },
      {
        ort: "Ardennen",
        datum: "2017-06-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.06.22_Ardennen.gpx?alt=media&token=249939fc-fda0-44b7-9c2d-35028109e2eb",
      },
      {
        ort: "Steinhuder Meer",
        datum: "2017-07-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.07.22_Steinhuder_Meer.gpx?alt=media&token=f4d30ad5-96a6-414c-9fbb-7d26d2908698",
      },
      {
        ort: "Harzdrenalin",
        datum: "2017-07-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.07.22_Harzdrenalin.gpx?alt=media&token=4a50ea36-92b2-4517-bb1e-d2e8846074b3",
      },
      {
        ort: "Enschede",
        datum: "2017-07-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.07.22_Enschede.gpx?alt=media&token=07328a6f-f046-40d9-883e-9a5ef718e7fc",
      },
      {
        ort: "Quedlinburg",
        datum: "2017-08-27",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.08.27_Quedlinburg.gpx?alt=media&token=407f2e75-9583-4812-9169-088f835b5501",
      },
      {
        ort: "Dolomiten",
        datum: "2017-09-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2017.09.15_Italien.gpx?alt=media&token=1fc7cea2-1d8d-4946-9cff-6c597efc499c",
      },
      {
        ort: "Dolomiten",
        datum: "2018-05-08",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.05.08_Italien.gpx?alt=media&token=97e787e7-1730-4708-9190-4a749f5b0d51",
      },
      {
        ort: "Meyer Werft",
        datum: "2018-06-03",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.06.03_Meyer_Werft.gpx?alt=media&token=9ad7871a-cbb3-4e18-9e55-1d80aecd4ac8",
      },
      {
        ort: "Thüring Schifergebirge",
        datum: "2018-06-21",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.06.21_Th%C3%BCring_Schifergebirge.gpx?alt=media&token=239fc2fb-bc3b-44fc-8492-e7786fc99abe",
      },
      {
        ort: "Bodenwerder",
        datum: "2018-07-29",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.07.29_Bodenwerder.gpx?alt=media&token=4c496814-6120-47aa-a823-ce8e45e2a733",
      },
      {
        ort: "Sardinien Routen",
        datum: "2018-09-20",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.09.20_Sardinien_Routen.gpx?alt=media&token=e26ec76a-9d49-4e56-99ad-3e54d10df66b",
      },
      {
        ort: "Möhnesee Sorpesee",
        datum: "2018-10-21",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2018.10.21_M%C3%B6hnesee_Sorpesee.gpx?alt=media&token=7dd3b175-508a-4015-ad55-bbc6fbc82d39",
      },
      {
        ort: "Steinhuder Meer",
        datum: "2019-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.05.01_Steinhuder_Meer.gpx?alt=media&token=a21c82a8-8c7d-48cf-8dc2-c48ba25f1b2e",
      },
      {
        ort: "Meyer Werft Papenburg",
        datum: "2019-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.05.01_Meyer_Werft_Papenburg.gpx?alt=media&token=ee242574-fbcb-44cb-8ef6-ee0512cf0012",
      },
      {
        ort: "Bodensee",
        datum: "2019-05-29",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.05.29_Bodensee.gpx?alt=media&token=d9ed1c15-23e8-41a3-8e24-1a23d1df193e",
      },
      {
        ort: "Aschaffenburg Spessart",
        datum: "2019-06-27",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.06.27_Aschaffenburg_Spessart.gpx?alt=media&token=1f2e43da-a266-4940-85f3-bc2347b61ca9",
      },
      {
        ort: "Harzer Bikeschmiede",
        datum: "2019-07-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.07.12_Harzer_Bikeschmiede.gpx?alt=media&token=732b4c40-c2d9-45c4-82e0-0f408b4c0ae4",
      },
      {
        ort: "Harz Stiege",
        datum: "2019-07-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.07.13_Harz_Stiege.gpx?alt=media&token=246aa6f3-7fd1-4f69-b675-d9bcb37bca11",
      },
      {
        ort: "Möhnesee Sorpesee",
        datum: "2019-08-17",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.08.17_M%C3%B6hnesee_Sorpesee.gpx?alt=media&token=15b3d1ba-6b5d-4f35-951a-0ac5d298a90b",
      },
      {
        ort: "Dolomiten",
        datum: "2019-09-21",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.09.21_Italien_ok.gpx?alt=media&token=b0ed9874-3f15-45ba-8573-2fbd82a7389d",
      },
      {
        ort: "Güntherskurve-Köterberg-Externsteine",
        datum: "2019-10-26",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2019.10.26_Guentherskurve-Koeterberg-Externsteine.gpx?alt=media&token=4c1fc6c0-f7f3-4a56-8967-c99b7db57fe7",
      },
      {
        ort: "Diemel Sauerland",
        datum: "2020-04-11",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.04.11_Diemel_Sauerland.gpx?alt=media&token=1fac2b8e-e062-467e-b631-287d26c13aaa",
      },
      {
        ort: "Emmerthal  Polle 177  T123",
        datum: "2020-04-11",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.04.11_Emmerthal_Polle%20177_T123.gpx?alt=media&token=6cd051c9-629e-4a5d-b500-01868cc3a606",
      },
      {
        ort: "Papenburg Meyer Werft ",
        datum: "2020-04-11",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.04.11_Meyer_Werft_Papenburg_OK.gpx?alt=media&token=7add8ece-2d4e-42d1-8e03-2748e56a8fa3",
      },
      {
        ort: "Möhne-Sorpe-Hennesee",
        datum: "2020-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.05.01_M%C3%B6hne-Sorpe-Hennesee_286_T173-195-240.gpx?alt=media&token=80a6d77f-b5b4-4da7-8fc8-4394c387ae5f",
      },
      {
        ort: "Günterskurve 140",
        datum: "2020-06-11",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.06.11_G%C3%BCnterskurve_140.gpx?alt=media&token=99545143-a921-4a86-8c2c-1d1068699234",
      },
      {
        ort: "Thueringer Wald",
        datum: "2020-06-25",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.06.25_Thueringer_Wald_OK.gpx?alt=media&token=7e38c9b4-f3bf-4e3d-aa7f-05b3fa54595d",
      },
      {
        ort: "Weser Imbiss Reileifzen",
        datum: "2020-07-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.07.12_Weser_Imbiss_Reileifzen_P62_P131_T180_P217_270.gpx?alt=media&token=4ea6ece9-a037-4b29-9f3b-f9d6aced88bf",
      },
      {
        ort: "Köln Dolofahrer",
        datum: "2020-07-18",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.07.18_Dolo-K%C3%B6ln_OK.gpx?alt=media&token=7d6c4471-c0ea-4e49-ba12-1e3146db3aaa",
      },
      {
        ort: "Halter am See",
        datum: "2020-08-08",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.08.08_Halter_am_See.gpx?alt=media&token=5ddf8165-6e4b-4454-b952-3b6d7cafe885",
      },
      {
        ort: "Italien",
        datum: "2020-09-18",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.09.18_Italien.gpx?alt=media&token=c96b7879-87a3-4451-9f3e-07bd403a8d3b",
      },
      {
        ort: "Schwarzwald Dolofahrer",
        datum: "2020-09-19",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.09.19_Dolo-Schwarzwald.gpx?alt=media&token=db98c246-e833-401a-93bf-f24ee0178f33",
      },
      {
        ort: "Emmerthal Köterberg Polle",
        datum: "2020-09-27",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.09.27_Emmerthal_K%C3%B6terberg_Polle_211_T174_P49_103_145.gpx?alt=media&token=4cef8575-88eb-4d13-bb5b-85d3083df7c7",
      },
      {
        ort: "Hagen Motodrom",
        datum: "2020-10-03",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.10.03_Motodrom_Hagen.gpx?alt=media&token=fddf1ff9-1b77-4360-8878-80995f4e8749",
      },
      {
        ort: "Polle Tonenburh",
        datum: "2020-10-24",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2020.10.24_Polle_Tonenburg_206_T153.gpx?alt=media&token=770587a5-85ca-4461-b29c-551f1d486c16",
      },
      {
        ort: "Spargelhof Winkelmann",
        datum: "2020-04-02",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.04.02_Spargelhof_Winkelmann_154.gpx?alt=media&token=4b1207b0-59f6-48be-a68b-bdaa07921c1e",
      },
      {
        ort: "Möhnesee & Sorpesee",
        datum: "2020-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.05.01_M%C3%B6hnesee_%26_Sorpesee_242_T168-195_1.gpx?alt=media&token=adb43310-d929-49e4-92ed-d5140c2b736c",
      },
      {
        ort: "Sassenberg Bad Iburg",
        datum: "2021-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.05.01_Sassenberg_Bad_Iburg_225_P55_113_T180.gpx?alt=media&token=3d608092-c7bb-47c6-9447-ed2e73c99413",
      },
      {
        ort: "Günterkurve 175",
        datum: "2021-05-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.05.13_G%C3%BCnterkurve_175.gpx?alt=media&token=8476a111-b2b3-4ff4-a076-c2bd0a96a69d",
      },
      {
        ort: "Bielefeld - Bad Pyrmont 249 P67 106 T160",
        datum: "2021-05-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.05.15_Bielefeld_-_Bad_Pyrmont_249_P67_106_T160.gpx?alt=media&token=027d2204-32d4-4e57-9f39-5e5f98eb28ae",
      },
      {
        ort: "Sauerland",
        datum: "2021-05-15",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.05.15_Sauerland.gpx?alt=media&token=bbbe9973-ecf4-4442-85a8-2470c810d262",
      },
      {
        ort: "Schloß Nordkirchen  Halter am See",
        datum: "2021-06-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.06.01_Schlo%C3%9F_Nordkirchen_Halter_am_See_270_T140.gpx?alt=media&token=f58320cf-7924-48b4-abd8-2a17b65a3879",
      },
      {
        ort: "Weser Imbiss Reileizen",
        datum: "2021-06-03",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.06.03_Weser_Imbiss_Reileizen_286_P71_P143_T158_P234.gpx?alt=media&token=ceb3d8b8-58de-46d1-bacd-e52505319767",
      },
      {
        ort: "Diemelsee Altes Kornhaus",
        datum: "2021-06-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.06.12_Diemelsee_Altes_Kornhaus_270_P101_179_231_T172.gpx?alt=media&token=f95f0abe-d6da-40a8-9d97-5e4cc1223cc3",
      },
      {
        ort: "Mosel XT",
        datum: "2021-06-12",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.06.24_Mosel_XT_ok_17.06.21.gpx?alt=media&token=4c0834d6-4c61-4574-8f19-384e77ed965a",
      },
      {
        ort: "XXL Schnitzelkönig",
        datum: "2021-07-17",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.07.17_XXL_Schnitzelk%C3%B6nig_OK.gpx?alt=media&token=29fb6431-ccd5-46ed-8e1c-32387e07f919",
      },
      {
        ort: "Dolo-Köln",
        datum: "2021-07-31",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.07.31_Dolo-K%C3%B6ln_OK.gpx?alt=media&token=de2f4b62-375b-406b-ad27-cc07236e2e26",
      },
      {
        ort: "Dolo-Köln st",
        datum: "2021-07-31",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.07.31_Dolo-K%C3%B6ln_st.gpx?alt=media&token=525997a5-1f46-4898-8a54-2266c4431d37",
      },
      {
        ort: "Motodrom Hagen",
        datum: "2021-08-07",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.08.07_Motodrom_Hagen.gpx?alt=media&token=af6b70c1-983c-4637-b5be-b67146bd820e",
      },
      {
        ort: "Weser Imbiss Burg Schwalenberg",
        datum: "2021-08-07",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.08.07_Weser_Imbiss_Burg_Schwalenberg_279_P71_P146_T157_P227.gpx?alt=media&token=fd71121b-2d56-4fe6-88c9-7bff59369738",
      },
      {
        ort: "Günterskurve Köterberg 204",
        datum: "2021-09-04",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.09.04_G%C3%BCnterskurve_K%C3%B6terberg_204_T133.gpx?alt=media&token=45ec277b-77cf-48fc-b8ea-9af65cab4f04",
      },
      {
        ort: "Schloß Nordkirchen - Halter am See - Harsewinkel 222",
        datum: "2021-10-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2021.10.16_A_Schlo%C3%9F_Nordkirchen_-_Halter_am_See_-_Harsewinkel_222_T183.gpx?alt=media&token=77f542a1-891d-4ecd-998f-fccb365e224d",
      },
      {
        ort: "Steinhuder Meer",
        datum: "2022-04-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022%20Steinhuder%20Meer.gpx?alt=media&token=cca5b617-b4d0-4619-8257-e5cc1ff700c3",
      },
      {
        ort: "Wilhemshaven Jadebusen",
        datum: "2022-04-08",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022%20Wilhemshaven%20Jadebusen.gpx?alt=media&token=87a2dfb7-a6b5-4926-8a19-cf1d6e43e1e5",
      },
      {
        ort: "Edersee",
        datum: "2022-05-01",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.05.01%20Edersee.gpx?alt=media&token=fd1bac14-6020-40ab-b169-a7f4876ebec4",
      },
      {
        ort: "Steinhuder Meer 277 P44 110 168 T172 P247",
        datum: "2022-05-14",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.05.14%20Steinhuder%20Meer%20277%20P44%20110%20168%20T172%20P247.gpx?alt=media&token=e7c05ea3-6171-40ec-be7a-28657dcc03cb",
      },
      {
        ort: "Urlaub 2022",
        datum: "2022-05-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.05.22%20Urlaub%202022.gpx?alt=media&token=336e9686-25d8-497e-945c-e90dc69868c3",
      },
      {
        ort: "BKK 2022",
        datum: "2022-06-23",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.06.23%20BKK%202022.gpx?alt=media&token=4a26c671-b322-48e2-beb1-6d3f699d9b3c",
      },
      {
        ort: "Mosel",
        datum: "2022-06-23",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.06.23%20Mosel.gpx?alt=media&token=c040e3f4-2271-4d69-99ee-0cd1f55b9bf4",
      },
      {
        ort: "Günterskurve 98 km",
        datum: "2022-07-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.07.13%20G%C3%BCnterskurve%2098%20km.gpx?alt=media&token=d6afa808-703f-459e-b839-58bd45c1d004",
      },
      {
        ort: "A Günterskurve Köterberg 204 T133",
        datum: "2022-07-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.07.16%20A%20G%C3%BCnterskurve%20K%C3%B6terberg%20204%20T133.gpx?alt=media&token=57120a01-08ab-466b-9bdf-0c64c25076eb",
      },
      {
        ort: "Köln Manni Horst",
        datum: "2022-07-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.07.16-17%20K%C3%B6ln%20Manni%20Horst.gpx?alt=media&token=f4ee78b1-7059-4f31-b5a5-0dd80a92d0b7",
      },
      {
        ort: "Harz Stiege Neu",
        datum: "2022-07-22",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.07.22%20Harz%20Stiege%20Neu.gpx?alt=media&token=16689b97-1e88-436b-a5dd-a13f8d5843d9",
      },
      {
        ort: "A Weser Imbiss Reileizen 265 P60 132  T147 P211",
        datum: "2022-08-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.08.13%20A%20Weser%20Imbiss%20Reileizen%20265%20P60%20132%20%20T147%20P211.gpx?alt=media&token=9538727a-f46f-46da-ac8a-b150decad4ff",
      },
      {
        ort: "Motodrom Hagen",
        datum: "2022-08-13",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.08.13%20Motodrom%20Hagen.gpx?alt=media&token=30901d14-aee6-4107-85e1-002027800e79",
      },
      {
        ort: "Günterskurve 98 km",
        datum: "2022-08-24",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.08.24%20G%C3%BCnterskurve%2098%20km.gpx?alt=media&token=684aa515-e380-4a6d-aa48-0c3f5c64df16",
      },
      {
        ort: "Diemel Sauerland 234 T125 P143 T188",
        datum: "2022-09-10",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.09.10%20Diemel%20Sauerland%20234%20T125%20P143%20T188.gpx?alt=media&token=4b2a1227-5ed2-41f0-92d7-ea013c278530",
      },
      {
        ort: "Österreich",
        datum: "2022-09-17",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.09.17%20%C3%96sterreich.gpx?alt=media&token=998a5f9b-f20c-431b-882d-7ccd68416c01",
      },
      {
        ort: "Weser Imbiss Reileifzen 260 P60 132  T147 P211",
        datum: "2022-10-16",
        download:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Routen%2F2022.08.13%20A%20Weser%20Imbiss%20Reileizen%20265%20P60%20132%20%20T147%20P211.gpx?alt=media&token=9538727a-f46f-46da-ac8a-b150decad4ff",
      },
    ],
  }),
  computed: {
    headers() {
      return [
        {
          text: "Datum",
          align: "start",
          value: "datum",
          width: "40px",
        },
        {
          text: "Ort",
          value: "ort",
        },
        {
          text: "Download",
          sortable: false,
          value: "download",
          width: "80px",
          justify: "center",
        },
      ];
    },
  },
  components: { AnfangsBild, BildmitText },
};
</script>

<style scoped></style>
