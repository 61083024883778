<template>
  <div>
    <!--<v-container fluid class="blue pa-0 d-none d-xl-flex">
      <v-row class="d-none d-xl-flex pt-16 pb-3">
        <v-img contain src="../assets/logos/logo.png" height="300px"></v-img>
      </v-row>
    </v-container>
    <div id="like_button_container"></div>
    <div
      id="animation"
      class="container-fluid row justify-content-center overflow-hidden position-relative d-none d-xl-flex m-0 pt-5 bg-primary"
    >
      <div class="container m-0 p-0 col-10 col-md-8 col-xl-4">
        <img
          id="logoAnhänger"
          class="img-fluid"
          src="../assets/logos/logoeingefahren.png"
          alt="Logo Anhänger"
        />
      </div>
      <div class="container m-0 p-0 col-10 col-md-8 col-xl-4">
        <img
          id="logoTruck"
          class="img-fluid truckDriveIn"
          src="../assets/logos/truck.png"
          alt="Logo Truck"
        />
      </div>
      <img
        id="go"
        class="position-absolute col-1"
        src="../assets/logos/go.png"
        alt="Vorwärtes"
      />
      <img
        id="replay"
        class="position-absolute col-1"
        src="../assets/logos/go.png"
        alt="Wiederholen"
      />
    </div>-->
  </div>
</template>

<script>
export default {
  /* mounted() {
    setTimeout(function () {
      var logo = document.getElementById("logoAnhänger");
      logo.src = "../assets/logos/Logo.png";
    }, 4000);
    setTimeout(function () {
      var go = document.getElementById("go");
      go.style.display = "block";
    }, 5000);
  },
  methods: {
    getGoArrow() {
      setTimeout(function () {
        var go = document.getElementById("go");
        go.style.display = "block";
      }, 5000);
    },
    getRestartArrow() {
      var logo = document.getElementById("logoAnhänger");
      var truck = document.getElementById("logoTruck");
      var replay = document.getElementById("replay");
      var go = document.getElementById("go");

      setTimeout(function () {
        logo.src = "../assets/logos/logoEingefahren.png";
      }, 2000);

      go.style.display = "none";

      logo.classList.remove("logoDriveIn");
      logo.classList.add("logoDriveOut");

      truck.classList.remove("truckDriveIn");
      truck.classList.add("truckDriveOut");

      setTimeout(function () {
        replay.style.display = "block";
      }, 6000);
    },
    restartAnimation() {
      var logo = document.getElementById("logoAnhänger");
      var truck = document.getElementById("logoTruck");
      var replay = document.getElementById("replay");
      //var go = document.getElementById("go");

      replay.style.display = "none";

      logo.classList.remove("logoDriveOut");
      logo.classList.add("logoDriveIn");

      truck.classList.remove("truckDriveOut");
      truck.classList.add("truckDriveIn");

      setTimeout(function () {
        logo.src = "../assets/logos/Logo.png";
      }, 4000);

      setTimeout(function () {
        var go = document.getElementById("go");
        go.style.display = "block";
      }, 5000);
    },
  },*/
};
</script>

<style scoped>
.blue {
  background: blue;
}
.schildDiv {
  position: absolute;
  left: 15%;
  top: -1rem;
  animation: schildAusklappen 7s;
  transform-origin: top;
}
.schildHalter {
  border-width: 0px 10px;
  border-style: solid;
  border-color: white;
}
.schild {
  border: 5px solid white;
  border-radius: 5px;
}
.schildNummer {
  position: absolute;
  bottom: 10%;
  left: 20%;
}
.schildArrow {
  position: absolute;
  bottom: 10%;
  right: 20%;
}
#go {
  transform: scale(0.6);
  transition: transform 0.25s;
  right: 25%;
  bottom: 10%;
  display: none;
}
#go:hover {
  transform: translate(10%, 0) scale(0.65);
}
#replay {
  transform: scale(0.6);
  transition: transform 0.25s;
  display: none;
  left: 0;
  bottom: 10%;
}
#replay:hover {
  transform: translate(10%, 0) scale(0.65);
}
#logoTruck {
  position: relative;
  top: 24%;
}
.logoDriveIn {
  animation: logoDriveIn;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}
.logoDriveOut {
  animation: logoDriveOut;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
.truckDriveIn {
  animation: truckDriveIn;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
.truckDriveOut {
  animation: truckDriveOut;
  animation-duration: 8s;
  animation-fill-mode: forwards;
}
@keyframes schildAusklappen {
  0% {
    transform: rotateX(90deg);
  }
  70% {
    transform: rotateX(90deg);
  }
  80% {
    transform: rotateX(-15deg);
  }
  90% {
    transform: rotateX(5deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes logoDriveIn {
  from {
    transform: translate(-300%, 0);
  }
  to {
    transform: translate(50%, 0);
  }
}
@keyframes truckDriveIn {
  0% {
    transform: translate(-300%, 0);
  }

  50% {
    transform: translate(50%, 0);
  }

  100% {
    transform: translate(300%, 0);
    display: none;
  }
}
@keyframes logoDriveOut {
  0% {
    transform: translate(50%, 0);
  }

  25% {
    transform: translate(50%, 0);
  }

  100% {
    transform: translate(300%, 0);
    display: none;
  }
}
@keyframes truckDriveOut {
  0% {
    transform: translate(300%, 0);
  }

  25% {
    transform: translate(50%, 0);
  }

  100% {
    transform: translate(300%, 0);
    display: none;
  }
}
</style>
