<template>
  <v-app>
    <head></head>
    <NavigationBar />
    <v-main>
      <router-view />
      <CookieBanner />
    </v-main>
    <FooterComp />
  </v-app>
</template>

<style>
.grecaptcha-badge {
  z-index: 999999 !important;
}
</style>

<script>
import NavigationBar from "./components/NavigationBar.vue";
import FooterComp from "./components/FooterComp.vue";
import CookieBanner from "./components/CookieBanner.vue";
export default {
  components: { NavigationBar, FooterComp, CookieBanner },
  name: "App",
  metaInfo: {
    title: "Motorrad Anhänger Verleih Bielefeld",
    titleTemplate: "%s | MAV Bielefeld",
    link: [
      {
        rel: "icon",
        href: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Fmav%20icon%20rounded.png?alt=media&token=e7983cb8-44bf-4950-9474-cf3d1b7b8c58",
      },
    ],
    htmlAttrs: {
      lang: "DE",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Wir verleihen Anhänger für bis zu 6 Motorräder in Bielefeld.",
      },
    ],
  },
};
</script>
