<template>
  <div>
    <AnfangsBild
      bild="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anfangsbilder%2Fwillkommen.jpg?alt=media&token=55934146-0aaf-48e5-845b-b3ca9c89031a"
      textErsteZeile="Willkommen bei"
      textRot="MAV"
      textZweiteZeile="-Bielefeld!"
    />
    <AnimationComp />
    <!-- Erste beiden Bilder mit Textboxen-->
    <BildmitText
      :bild="imageTextComponentFirst.imageSrc"
      :titel="imageTextComponentFirst.titel"
      :text="imageTextComponentFirst.text"
      :align="imageTextComponentFirst.align"
    />
    <BildmitText
      :bild="imageTextComponentSecond.imageSrc"
      :titel="imageTextComponentSecond.titel"
      :text="imageTextComponentSecond.text"
      :align="imageTextComponentSecond.align"
    />

    <!--Karoussel der Anhängerflotte-->
    <v-container
      fluid
      style="max-width: 1365px"
      class="text-center mt-lg-16 pt-lg-16 mt-8 pt-8 mb-0 align-center"
    >
      <p class="text-md-h4 text-h5 font-weight-bold">
        Übersicht unserer Anhängerflotte
      </p>
      <p class="text-lg-body-1">
        Mit unserer Motorradanhängerflotte könnt Ihr bis zu 6 Motorräder
        transportieren. Unsere Anhängerflotte besteht aus Tieflader, Hochlader
        und absenkbaren Anhänger. Mit unseren Motorradwippen ist auch eine
        Beladung mit einer Person möglich. Die Motorradanhänger sind in einem
        technisch einwandfreien, guten Zustand und sind gut ausgestattet. Unsere
        Anhänger besitzen eine Haftpflichtversicherung inklusive Teilkasko.
      </p>
    </v-container>
    <AnhaengerShow />
    <!--Zwischenbild und Anfragecontainer-->
    <v-img
      src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Fruckspiegel.png?alt=media&token=67aae7cb-460c-408f-95a0-8561f324e3b9"
      lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Fruckspiegel.png?alt=media&token=67aae7cb-460c-408f-95a0-8561f324e3b9"
      class=""
    ></v-img>
    <TextmitButton
      :titel="textButtonComponentFirst.titel"
      :text="textButtonComponentFirst.text"
      :button="textButtonComponentFirst.button"
      :to="textButtonComponentFirst.to"
    />
    <!--Zwischenbild und Routencontainer-->
    <v-img
      contain
      src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Frouten-Abschnitt.jpg?alt=media&token=56233d19-7094-4013-910a-896a93668c14"
      lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Frouten-Abschnitt.jpg?alt=media&token=56233d19-7094-4013-910a-896a93668c14"
      class="mt-16"
    ></v-img>
    <TextmitButton
      :titel="textButtonComponentSecond.titel"
      :text="textButtonComponentSecond.text"
      :button="textButtonComponentSecond.button"
      :to="textButtonComponentSecond.to"
    />
    <!--Das sind wir Abschnitt-->
    <!--BildmitText-->
    <BildmitText
      :bild="imageTextComponentDasSindWir.imageSrc"
      :titel="imageTextComponentDasSindWir.titel"
      :text="imageTextComponentDasSindWir.text"
      :align="imageTextComponentDasSindWir.align"
      :flipped="imageTextComponentDasSindWir.flipped"
    />
  </div>
</template>

<style></style>

<script scoped>
import AnfangsBild from "@/components/AnfangsBild.vue";
import BildmitText from "@/components/BildmitText.vue";
import AnhaengerShow from "@/components/AnhaengerShow.vue";
import TextmitButton from "@/components/TextmitButton.vue";
import AnimationComp from "@/components/AnimationComp.vue";

export default {
  name: "HomeView",
  data: () => ({
    imageTextComponentFirst: {
      titel: "Motorradanhänger",
      text: "Von Tieflader über Hochlader bis zu absenkbaren Anhänger, wir vermieten Motorradanhänger bis zu 6 Motorrädern. Dazu bieten wir diverses Zubehör für eine sichere und bequeme Reise an. Darunter zum Beispiel Motorradwippen, die bei jedem Anhänger dabei sind. Ob Dolomiten, Sauerland oder Ardennen, unsere Anhänger ermöglichen eine enspannte Reise für einen abenteuerlichen Aufenthalt.",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FMotorrad_auf_Haenger.jpg?alt=media&token=82d4dc66-fe43-43e2-8188-f21022483649",
      align: "start",
    },
    imageTextComponentSecond: {
      titel: "Über uns",
      text: "2008 haben wir zu zweit mit der Vermietung unserer Anhänger begonnen. Unsere Flotte ist seitdem ständig gewachsen und wächst weiterhin. Wir sind sehr stolz auf unseren Fuhrpark, der uns schon und noch lange belgeitet. Wir arbeiten mit viel Herzblut und Freude. Eure Wünsche versuchen wir sogar in unsere Umbauten einfließen zu lassen. Da wir selber Motorrad fahren, wissen wir, worauf es bei einem Motorrad Urlaub ankommt. Bequem, trocken, sicher ans Ziel ankommen und das ohne große Kosten.",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FKontakt.png?alt=media&token=ddd4fa2b-806d-4cda-a00b-95ba749efdc9",
      align: "start",
    },
    imageTextComponentDasSindWir: {
      titel: "Das sind wir",
      text: "Das sind wir, Stefan und Horst. Zusammen haben wir MAV Bielefeld Ende 2008 gegründet. Für uns war es schwer mit mehreren Motorrädern in den Urlaub zu fahren, da die örtlichen Vermietungen noch nicht ausgereift waren. Daraus enstand unsere Vermietung, MAV Bielefeld. Wir sind immer auf der Suche nach Verbesserungen, die wir in die Sicherheit des Anhängers und in den Transport eurer Motorräder einfließen lassen. Am liebsten fahren wir mit unserer Gruppe in die Dolomiten nach Südtirol. Wir haben schon einiges mitgemacht und freuen uns, unsere Erfahrungen mit Euren zu tauschen.",

      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FDas_sind_wir.jpg?alt=media&token=5038302f-9f9c-408c-8b7e-3264adc6013a",
      align: "start",
      flipped: false,
    },
    textButtonComponentFirst: {
      titel: "Mietpreise & Anfrage",
      text: "Konnten wir Eurer Interesse wecken? Hier gelangt Ihr zu unseren Preisen und Anfragen. Wir freuen uns auf Eure Anfrage! ",
      button: "Zur Mietanfrage",
      to: "PreiseAnfrage",
    },
    textButtonComponentSecond: {
      titel: "Routen zum Downloaden",
      text: "Wir sind begeisterte Motorradfahrer. An unseren selbstgeplanten Strecken wollen wir Euch auch teilhaben lassen. Schaut gerne bei unseren Routen vorbei.",
      button: "Zur Downloadliste",
      to: "Routen",
    },
  }),
  methods: {
    goToHaenger(ref) {
      console.log(ref);
      this.$router.push({
        path: "/Fuhrpark",
        hash: "#" + ref,
      });
    },
  },
  components: {
    AnfangsBild,
    BildmitText,
    AnhaengerShow,
    TextmitButton,
    AnimationComp,
  },
};
</script>
