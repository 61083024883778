<template>
  <v-container style="max-width: 1185px" class="">
    <v-card flat
      ><v-card-title class="text-md-h3 text-h4 mt-8"
        >Mietbedingungen</v-card-title
      ></v-card
    >
    <v-container class="px-0">
      <v-card
        flat
        v-for="(item, index) in MietbedingungenListe"
        :key="index"
        class="text-justify"
      >
        <v-card-title class="font-weight-bold">{{ item.title }}</v-card-title>
        <v-card-text>{{ index + 1 }}) <span /> {{ item.text }}</v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "MietbedinungenView",
  metaInfo: {
    title: "Mietbedinungen",
  },
  data: () => ({
    MietbedingungenListe: [
      {
        title: "",
        text: "Die Mietgegenstände werden von dem Vermieter dem Mieter kostenlos zur Verfügung gestellt. Die Benutzung des Anhängers geschieht auf eigenes Risiko. Der Vermieter haftet nur für die von ihm abgeschlossenen Versicherungsleistungen und nicht darüber hinaus.",
      },
      {
        text: "Für Beschädigungen, an den zu transportierenden Gegenständen, haftet allein der Mieter.",
      },
      {
        text: "Eine KFZ- Haftpficht mit Teilkasko Versicherung für den Anhänger ist in der Miete eingeschlossen. Eine Versicherung für die transportierende Gegenstände auf den Anhängern, ist der Mieter selbst zuständig.",
      },
      {
        text: "Nach Ablauf der Miete ist das Mietobjekt in gereinigtem und technisch einwandfreiem Zustand dem Vermieter zurückzugeben. Andernfalls wird für die Reinigung mind. 25,00 € berechnet.",
      },
      {
        text: "Will der Mieter die Mietdauer verlängern, so hat er dies dem Vermieter frühzeitig mitzuteilen. Die Verlängerung ist nur möglich, wenn das Mietobjekt nicht von jemand anderem reserviert ist. Sollte der Anhänger nicht zum Rückgabedatum zurückgebracht werden, ist für jeden weiteren Kalendertag ein Betrag von 50,- € fällig. Eventuelle Zusatzkosten  die durch den Nachmieter entstehen, trägt der Mieter.",
      },
      {
        text: "Bei Beschädigungen am Anhänger oder Verzurrmaterial haftet der Mieter. Die daraus entstehenden Reparaturkosten und die Ersatzteilkosten gehen zu Lasten des Mieters.",
      },
      {
        text: "Bei Beschädigungen/Unfall ist der Vermieter sofort zu informieren. Sollte keine Information erfolgen, können Folgekosten entstehen, die der Mieter tragen muss.",
      },
      {
        text: "Wird der Mietgegenstand vom Mieter vorzeitig zurückgegeben, kann daraus keine Mietminderung abgeleitet werden.",
      },
      {
        text: "Das Verzurrmaterial wird kostenlos zur Verfügung gestellt. Der Gurt hat eine Zugfestigkeit von 500 kg. Vor jedem Verzurren sind die Gurte auf Schäden zu überprüfen und ggf. auszutauschen. Nach Fahrtantritt, ca. 30km, sind die Gurte nachzuspannen, da sich die Gurte setzen. Während der Fahrt sind die Verzurrungen mehrmals zu überprüfen.",
      },
      {
        text: "Falls der Schlüsselsatz, der Ersatzschlüsselbund oder ein Schloss verloren geht, ist ein Betrag von 30,- € fällig.",
      },
      {
        text: "Sollte unter Umständen der Vermieter die Verzurrung der Motorräder vornehmen, können für diesen Service Kosten für den Mieter entstehen. Die Verzurrung ist -ohne Gewähr-.",
      },
      {
        text: "Gibt der Mieter bei seiner Buchung nicht Hersteller und Typ des Motorrades an, kann es sein, dass der Anhänger für diese Motorräder nicht geeignet ist. Folgekosten können dem Vermieter nicht in Rechnung gestellt werden.",
      },
      {
        text: "Für 100km/h: entscheidend ist das Leergewicht des Zugfahrzeugs. Beim Gesamtgewicht des Anhängers von 1300 kg/ 1500 kg/ 1600 kg/ 1800 kg darf das Leergewicht des Zugfahrzeuges mind. 1185 kg/ 1365 kg/ 1455 kg/ 1636 kg betragen.",
      },
      {
        text: "Wenn ein Radwechsel durchgeführt wird, erlischt die 100 km/h -Genehmigung des Anhängers.",
      },
      {
        text: "Sollte eine Strafverfügung/Geschwindigkeitsüberschreitung erfolgen, werden die erforderlichen Daten an das jeweilige Amt ohne Rücksprache weitergeleitet.",
      },
      {
        text: "Haftungsausschluss: Die Spanngurte, Ratschen und Hinterradbefestigungen, die sich in der Notfallbox befinden, sind nicht nach EN12195-2 gefertigt und getestet worden. Sollte der Mieter die Spanngurte, Ratschen und Hinterradbefestigungen benutzen, geschieht dieses auf eigene Gefahr. Für Schäden und Verletzungen an den zu transportierenden Gegenständen und Personen ist der Motorradanhängerverleih Bielefeld nicht haftbar.",
      },
      {
        text: "Die Miete und die Kaution ist bei der Abholung zu zahlen. Der Fahrzeugschein, die Infomappe und die Schlüssel werden bei Abholung übergeben.",
      },
      {
        text: "Als Mieter bestätige ich, den Anhänger in einem verkehrstauglichen Zustand und auch die Gurte in einem ordnungsgemäßen Zustand erhalten zu haben. Eine Einweisung in die Verzurrung des Motorrades auf dem Anhänger habe ich erhalten. Die o.g. Mietkonditionen und die AGBs auf unsere Homepage mav-bi.de sind Bestandteile dieses Vertrages. Ich habe die Bedingungen gelesen und erkenne diese an.",
      },
    ],
  }),
};
</script>

<style></style>
