<template>
  <v-container fluid style="max-width: 1365px" class="mt-lg-16 mt-8">
    <v-row class="justify-center px-md-10" :align="align">
      <v-col md="6" cols="11" class=""
        ><v-img :aspect-ratio="16 / 9" :src="bild" :lazy-src="bild"></v-img
      ></v-col>
      <v-col
        md="6"
        cols="11"
        :order="textOrderDefault"
        :order-md="textOrderMD"
        class=""
        ><p class="text-md-h4 text-h5 font-weight-bold">{{ titel }}</p>
        <p class="mb-0 mt-md-5 text-lg-body-1">
          {{ text }}
        </p></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    bild: String,
    titel: String,
    text: String,
    align: String,
    flipped: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    textOrderMD() {
      if (this.flipped) {
        return "first";
      }
      return "last";
    },
    textOrderDefault() {
      if (this.flipped) {
        return "last";
      }
      return "first";
    },
  },
};
</script>

<style></style>
