// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyBwtIkvcskvr1AwbkFXnnRDIUxpFqdvAm8",

  authDomain: "mav-web-f1fa4.firebaseapp.com",

  projectId: "mav-web-f1fa4",

  storageBucket: "mav-web-f1fa4.appspot.com",

  messagingSenderId: "469710826963",

  appId: "1:469710826963:web:af2f5282980159f32d9326",

  measurementId: "G-WM72T15BPE"

};





// Initialize Firebase

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);