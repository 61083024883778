<template>
  <v-container style="max-width: 1185px" class="">
    <v-container class="px-0">
      <v-card flat
        ><v-card-title class="text-md-h3 text-h4 mt-8">Impressum</v-card-title
        ><v-card-text
          >Verantwortlich nach § 6 Abs.2 MDStV und Inhaber der Domain
          www.mav-bi.de ist:<br />
          Stefan Mechsner<br />
          Stargarderstr, 28<br />
          33699 Bielefeld<br />
          Telefon-Nr: 0176 / 56233264<br />
          E-Mail: mav-bielefeld@gmx.de<br />
          Geschäftsführer: Stefan Mechsner und Horst Vilmar GbR<br /> </v-card-text></v-card
    ></v-container>
    <v-container class="px-0">
      <v-card
        flat
        v-for="(item, index) in ImpressumListe"
        :key="index"
        class="my-8"
      >
        <v-card-title class="font-weight-bold">{{ item.title }}</v-card-title>
        <v-card-text>{{ item.text }}</v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "ImpressumView",
  metaInfo: {
    title: "Impressum",
  },
  data: () => ({
    ImpressumListe: [
      {
        title: "Besonderheiten bei Kleinunternehmern",
        text: "Alle angegebenen Preise sind Endpreise zzgl. Liefer-/Versandkosten. Aufgrund des Kleinunternehmerstatus gem. § 19 UStG erheben wir keine Umsatzsteuer und weisen diese daher auch nicht aus.",
      },
      {
        title: "Keine Abmahnung ohne vorherigen Kontakt!",
        text: "Sollte irgendwelcher Inhalt oder die designtechnische Gestaltung einzelner Seiten oder Teile dieses Internetauftritts Rechte Dritter oder gesetzliche Bestimmungen verletzen oder anderweitig in irgendeiner Form wettbewerbsrechtliche Probleme hervorbringen, so bitte ich um eine angemessene, ausreichend erläuternde und schnelle Nachricht ohne Kostennote. Ich garantiere, dass die zu Recht beanstandeten Passagen oder Teile des Onlineangebotes unverzüglich entfernt bzw. den rechtlichen Vorgaben umfänglich angepasst werden, ohne dass von Ihrer Seite die Einschaltung eines Rechtsbeistandes erforderlich ist.",
      },
      {
        title: "Urheberrecht",
        text: "Die Veröffentlichungen auf diesen Seiten unterliegen dem deutschen Urheberrecht. Vervielfältigung, Bearbeitung und jede Art der Verwertung ausserhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung von Stefan Mechsner.",
      },
      {
        title: "Copyright",
        text: "Das Copyright an Musik, Bildern, Texten sowie dem Design und den Grafikelementen auf dieser Website liegt ausschließlich bei dem Betreiber dieser Website. Jede nicht authorisierte Verwendung - egal ob kommerziell oder nicht kommerziell - ist untersagt und kann und wird gegebenenfalls rechtlich verfolgt. In diesem Zusammenhang bin ich für alle Hinweise über unrechtmäßig genutztes Material dankbar.",
      },
      {
        title: "Haftung für Inhalte",
        text: "Die Informationen auf dieser Seite sind allgemeiner und informativer Art. Die Inhalte dieser Seiten werden mit größter Sorgfalt erstellt. Für Richtigkeit, Vollständigkeit und Aktualität dieser Inhalte kann jedoch keine Haftung übernommen werden.",
      },
      {
        title: "Haftung für Links",
        text: "Die aufgeführten externen Links führen zu Inhalten fremder Anbieter. Für diese Inhalte ist der jeweilige Anbieter verantwortlich.",
      },
    ],
  }),
};
</script>

<style></style>
