<template>
  <v-container
    fluid
    style="max-width: 1365px"
    class="mt-lg-16 pt-lg-16 pt-8 mt-8 mb-lg-16 pb-lg-16 mb-8 pb-8"
    ><p class="text-center text-md-h4 text-h5 font-weight-bold">
      {{ titel }}
    </p>
    <v-row class="justify-center mt-8 px-0 px-lg- px-md-10">
      <v-col cols="11" md="6" class="mb-0 text-lg-body-1">
        {{ text }}
      </v-col>
      <v-col cols="9" md="6" class=""
        ><v-btn
          color="rgb(48, 66, 83, 1)"
          dark
          x-large
          block
          :to="{ name: to }"
        >
          {{ button }}</v-btn
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    titel: String,
    text: String,
    button: String,
    to: String,
  },
};
</script>

<style></style>
