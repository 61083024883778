<template>
  <div class="pb-lg-16 pb-8">
    <v-img
      contain
      :src="bild"
      :lazy-src="bild"
      class=""
    >
      <div class="pa-0 mt-5 mt-sm-16">
        <v-card
          tile
          dark
          color="rgb(48, 66, 83, 0.6)"
          class="d-flex justify-center pl-4 pr-4"
        >
          <v-card-title
            class="text-lg-h1 text-md-h2 text-sm-h3 text-h4 pa-0 flex-grow-1 flex-shrink-0"
            style="max-width: 1645px"
          >
            <p class="ma-0 pt-md-10 pb-md-10 pt-5 pb-5 font-weight-bold">
              {{ textErsteZeile }} <br /><span class="red--text">{{
                textRot
              }}</span
              >{{ textZweiteZeile }}
            </p>
          </v-card-title>
        </v-card>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  props: ["bild", "textErsteZeile", "textRot", "textZweiteZeile"],
};
</script>

<style></style>
