import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import FuhrparkView from "../views/FuhrparkView.vue";
import RoutenView from "../views/RoutenView.vue";
import PreiseAnfrageView from "../views/PreiseAnfrageView.vue";
import StandorteView from "../views/StandorteView.vue";
import KontaktView from "../views/KontaktView.vue";
import AGBView from "../views/AGBView.vue";
import DatenschutzView from "../views/DatenschutzView.vue";
import ImpressumView from "../views/ImpressumView.vue";
import VueMeta from 'vue-meta'
import MietbedingungenView from "../views/MietbedingungenView.vue";
import Vuetify from "vuetify/lib";
//import { colors } from "vuetify/lib";


Vue.use(VueRouter);

Vue.use(VueMeta)

Vue.use(Vuetify);


const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/Fuhrpark",
    name: "Fuhrpark",
    component: FuhrparkView,
  },
  {
    path: "/Routen",
    name: "Routen",
    component: RoutenView,
  },
  {
    path: "/Preise_und_Anfrage",
    name: "PreiseAnfrage",
    component: PreiseAnfrageView,
  },
  {
    path: "/Standorte",
    name: "Standorte",
    component: StandorteView,
  },
  {
    path: "/Kontakt",
    name: "Kontakt",
    component: KontaktView,
  },
  {
    path: "/AGB",
    name: "AGB",
    component: AGBView,
  },
  {
    path: "/Datenschutz",
    name: "Datenschutz",
    component: DatenschutzView,
  },
  {
    path: "/Impressum",
    name: "Impressum",
    component: ImpressumView,
  },
  {
    path: "/Mietbedingungen",
    name: "Mietbedingungen",
    component: MietbedingungenView,
  },
  { 
    path: '/:catchAll(.*)',
    component: HomeView,
    name: 'NotFound'
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      console.log(to.hash);
      return {};
    }
    return document
      .getElementById("app")
      .scrollIntoView({ behavior: "smooth" });
  },
  /* icons: {
    iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: colors.blueGrey.lighten3, // #E53935
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.purple.base, // #3F51B5
      },
    },
  },*/
});

export default router;
