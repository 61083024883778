<template>
  <v-container
    class="mb-lg-16 mb-8 pb-lg-16 pb-8 px-0"
    style="max-width: 1365px"
  >
    <v-row class="mx-auto mt-5 mb-5 justify-center">
      <v-btn icon @click="previousSlide()" small
        ><font-awesome-icon
          size="2x"
          color="rgb(48, 66, 83, 1)"
          icon="fa-solid fa-angle-left" /></v-btn
      ><v-btn
        small
        icon
        @click="slideGroup = index"
        v-for="(list, index) in slideGroupListe"
        :key="index"
        ><font-awesome-icon
          v-if="slideGroup == index"
          color="rgb(48, 66, 83, 1)"
          icon="fa-solid fa-circle"
          size="2x" />
        <font-awesome-icon
          v-else
          color="rgb(48, 66, 83, 1)"
          icon="fa-regular fa-circle"
          size="2x" /></v-btn
      ><v-btn icon @click="nextSlide()" small>
        <font-awesome-icon
          color="rgb(48, 66, 83, 1)"
          icon="fa-solid fa-angle-right"
          size="2x" /></v-btn
    ></v-row>
    <v-slide-group v-model="slideGroup" center-active>
      <template v-slot:prev></template>
      <template v-slot:next></template>
      <v-slide-item v-for="(haenger, index) in slideGroupListe" :key="index">
        <v-card
          v-if="slideGroup == index"
          class="ma-6 pa-5 pb-0"
          :elevation="10"
          width="350px"
          height="350px"
          color="rgb(48, 66, 83, 1)"
          @click="goToHaenger(haenger.ref)"
        >
          <div class="pa-1" style="background-color: white">
            <v-img
              :src="haenger.bild"
              :lazy-src="haenger.bild"
              :aspect-ratio="4 / 3"
              width="300px"
            ></v-img>
          </div>
          <v-card-subtitle class="white--text text-md-h5 text-h6 pb-0">{{
            haenger.bezeichnung
          }}</v-card-subtitle>
          <v-card-subtitle class="pt-0 grey--text">{{
            haenger.motorrad
          }}</v-card-subtitle>
        </v-card>
        <v-card
          v-else
          class="ma-4 pa-5"
          width="350px"
          height="350px"
          color="rgb(48, 66, 83, 1)"
          @click="goToHaenger(haenger.ref)"
        >
          <v-img
            :src="haenger.bild"
            :lazy-src="haenger.bild"
            :aspect-ratio="4 / 3"
            width="300px"
          ></v-img>
          <v-card-title class="white--text text-md-h5 text-h6">{{
            haenger.bezeichnung
          }}</v-card-title>
          <v-card-subtitle class="grey--text">{{
            haenger.motorrad
          }}</v-card-subtitle>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    slideGroup: 0,
    model: null,
    slideGroupListe: [
      {
        bezeichnung: "MV 16 Absenkbar",
        ref: "mv16",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F16.png?alt=media&token=59c0fba3-3ebf-402b-a7f1-ca8ee94693cf",
        motorrad: "Bis zu 1 Motorrad",
      },
      {
        bezeichnung: "MV 27 Absenkbar",
        ref: "mv27",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2Fmv_27.jpg?alt=media&token=63f75d6f-f97e-4c55-8f27-931227ac6424",
        motorrad: "Bis zu 2 Motorrädern",
      },
      {
        bezeichnung: "MV 37",
        ref: "mv37",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F37.png?alt=media&token=b945d112-79ee-445e-ae53-1371d26da1ca",
        motorrad: "Bis zu 3 Motorrädern",
      },
      {
        bezeichnung: "MV 40",
        ref: "mv40",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F41.png?alt=media&token=f5e3f5d6-7004-48f7-8337-e8e7b2f55bd8",
        motorrad: "Bis zu 4 Motorrädern",
      },
      {
        bezeichnung: "MV 41",
        ref: "mv41",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F41.png?alt=media&token=f5e3f5d6-7004-48f7-8337-e8e7b2f55bd8",
        motorrad: "Bis zu 4 Motorrädern",
      },
      {
        bezeichnung: "MV 42",
        ref: "mv42",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F42.png?alt=media&token=f28b94fc-2bc8-49f4-957f-f76ee3e26e87",
        motorrad: "Bis zu 6 Motorrädern",
      },
      {
        bezeichnung: "MV 62",
        ref: "mv62",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F62.png?alt=media&token=0cedbeb5-3f7c-47c8-b232-ce0ab5f3997e",
        motorrad: "Bis zu 3 Motorrädern",
      },
      {
        bezeichnung: "MV 63",
        ref: "mv63",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F63.png?alt=media&token=1dfeb8d3-3b1d-4517-a61d-aa7ea30aa815",
        motorrad: "Bis zu 3 Motorrädern",
      },
      {
        bezeichnung: "MV 64",
        ref: "mv64",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2FMV64.jpg?alt=media&token=3274e09e-85fd-4490-9a81-12f96f19df57",
        motorrad: "Bis zu 3 Motorrädern",
      },
      {
        bezeichnung: "MV 65",
        ref: "mv65",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F65.png?alt=media&token=8fb9ffb4-78ff-4ef7-b7bb-b5449485f60d",
        motorrad: "Bis zu 3 Motorrädern",
      },
      {
        bezeichnung: "MV 116",
        ref: "mv116",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2FMV116.png?alt=media&token=ba34157b-b294-461d-9cf2-5ef05d0e50a9",
        motorrad: "Bis zu 2 Motorrädern",
      },
    ],
  }),
  methods: {
    goToHaenger(ref) {
      this.$router.push({
        path: "/Fuhrpark",
        hash: "#" + ref,
      });
    },
    nextSlide() {
      this.slideGroup++;
      this.slideGroup == 10 ? (this.slideGroup = 0) : "";
    },
    previousSlide() {
      this.slideGroup--;
      this.slideGroup == -1 ? (this.slideGroup = 9) : "";
    },
  },
};
</script>

<style></style>
