<template>
  <v-banner
    outlined
    style="
      position: fixed;
      z-index: 9999999999;
      bottom: 0;
      background-color: white;
    "
    >Wir verwenden Cookies, um die Benutzerfreundlichkeit unserer Webseite zu
    verbessern.
    <template v-slot:actions="{ dismiss }">
      <v-btn color="#304253" class="white--text" @click="dismiss"> Ok </v-btn>
      <v-btn color="#304253" class="white--text" :to="{ name: 'Datenschutz' }">
        Mehr Informationen
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
export default {
  data: () => ({
    cookie: true,
  }),
};
</script>

<style></style>
