<template>
  <div>
    <!--Obere NavBar-->
    <v-app-bar app dark style="background-color: #304253" height="80">
      <div
        class="d-flex mx-auto align-center flex-grow-1 flex-shrink-0"
        style="max-width: 1645px"
      >
        <router-link :to="{ name: 'Home' }">
          <v-img
            alt="MAV Logo"
            class="shrink mr-5 pl-0"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
            lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
            transition="scale-transition"
            width="60"
          />
        </router-link>
        <div class="d-flex">
          <router-link
            class="text-decoration-none d-none d-sm-block white--text text-h6"
            :to="{ name: 'Home' }"
            >MAV - <span class="red--text">M</span>otorrad
            <span class="red--text">A</span>nhänger
            <span class="red--text">V</span>erleih Bielefeld</router-link
          >
          <router-link
            class="text-decoration-none white--text d-sm-none text-h6"
            :to="{ name: 'Home' }"
          >
            <span class="red--text">MAV</span> - Bielefeld
          </router-link>
        </div>
        <v-spacer></v-spacer>

        <!--direkte Links für größer als md-->
        <ul class="align-center d-none d-md-block text-h7">
          <router-link
            v-for="(seite, index) in NavListe"
            :key="index"
            class="text-decoration-none px-5 white--text"
            :to="seite.name"
            >{{ seite.titel }}</router-link
          >
        </ul>
      </div>
      <!--Hamburger bei medium Geräten-->
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="d-md-none mr-0"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <!--Hamburger Content-->
    <v-navigation-drawer app v-model="drawer" temporary right>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="">
          <v-list-item
            v-for="(seite, index) in NavListe"
            :key="index"
            :to="seite.name"
          >
            <v-list-item-title>{{ seite.titel }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    NavListe: [
      {
        titel: "Fuhrpark",
        name: "Fuhrpark",
      },
      {
        titel: "Preise & Anfrage",
        name: "Preise_und_Anfrage",
      },
      {
        titel: "Routen",
        name: "Routen",
      },
      {
        titel: "Standorte",
        name: "Standorte",
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.parent-class >>> .v-toolbar__content {
  padding: 0px !important;
}
</style>
