<template>
  <div>
    <AnfangsBild
      bild="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anfangsbilder%2Fwillkommen.jpg?alt=media&token=55934146-0aaf-48e5-845b-b3ca9c89031a"
      textErsteZeile="Unser"
      textZweiteZeile="Anhängerfuhrpark"
    />
    <v-container
      style="max-width: 1365px"
      class=" "
      v-for="(haenger, index) in anhaenger"
      :key="index"
      :id="haenger.ref"
    >
      <v-row class="text-lg-body-1 justify-center align-center mt-lg-16 mt-8">
        <v-col cols="11" md="6" class="">
          <v-img
            :aspect-ratio="4 / 3"
            :src="haenger.bild"
            :lazy-src="haenger.bild"
            class="d-none d-md-block"
          ></v-img
          ><v-img
            :aspect-ratio="16 / 9"
            :src="haenger.bild"
            :lazy-src="haenger.bild"
            class="d-md-none"
          ></v-img
        ></v-col>
        <v-col cols="11" md="6" class="py-0" style="max-height: 500px"
          ><p class="text-md-h4 text-h5 font-weight-bold">
            {{ haenger.bezeichnung }}
          </p>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>max. Motorräder:</v-list-item-content
              ><v-list-item-content class="align-end">{{
                haenger.motorraeder
              }}</v-list-item-content></v-list-item
            >

            <v-list-item
              ><v-list-item-content>zul. Geschwindigkeit:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.geschwindigkeit }} km/h</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Gesamtgewicht:</v-list-item-content>
              <v-list-item-content class="align-end"
                >{{ haenger.gewicht }} kg</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Zuladung:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.zuladung }} kg</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Leergewicht:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.leergewicht }} kg</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content
                >Leergewicht des Zugfahrzeuges für 100km/h:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.leergewichtZugfahrzeug }} kg</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Plattformmaß:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.plattformmass }} m</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Anhängerbreite:</v-list-item-content
              ><v-list-item-content class="align-end"
                >{{ haenger.anhaengerbreite }} m</v-list-item-content
              ></v-list-item
            >
            <v-list-item
              ><v-list-item-content>Auflaufbremse:</v-list-item-content
              ><v-list-item-content class="align-end">{{
                haenger.auflaufbremse
              }}</v-list-item-content></v-list-item
            >
            <v-list-item
              ><v-list-item-content
                >montierbarer Fahrradträger:</v-list-item-content
              ><v-list-item-content class="align-end">{{
                haenger.fahrradtraeger
              }}</v-list-item-content></v-list-item
            >
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AnfangsBild from "@/components/AnfangsBild.vue";
export default {
  name: "FuhrparkView",
  metaInfo: {
    title: "Fuhrpark",
  },
  data: () => ({
    anhaenger: [
      {
        bezeichnung: "MV 16 Absenkbar",
        ref: "mv16",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F16.png?alt=media&token=59c0fba3-3ebf-402b-a7f1-ca8ee94693cf",
        gewicht: "1300",
        motorraeder: "1",
        plattformmass: "1.28 x 2.5",
        anhaengerbreite: "2.0",
        leergewicht: "350",
        zuladung: "950",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "nein",
      },
      {
        bezeichnung: "MV 27 Absenkbar",
        ref: "mv27",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2Fmv_27.jpg?alt=media&token=63f75d6f-f97e-4c55-8f27-931227ac6424",
        gewicht: "1300",
        motorraeder: "2",
        plattformmass: "1.53 x 2.5",
        anhaengerbreite: "2.2",
        leergewicht: "420",
        zuladung: "880",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "nein",
      },
      {
        bezeichnung: "MV 37",
        ref: "mv37",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F37.png?alt=media&token=b945d112-79ee-445e-ae53-1371d26da1ca",
        gewicht: "1200",
        motorraeder: "3",
        plattformmass: "1.7 x 3.4",
        anhaengerbreite: "2.15",
        leergewicht: "350",
        zuladung: "850",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1095",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 40",
        ref: "mv40",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F41.png?alt=media&token=f5e3f5d6-7004-48f7-8337-e8e7b2f55bd8",
        gewicht: "1600",
        motorraeder: "4",
        plattformmass: "1.8 x 4.07",
        anhaengerbreite: "2.3",
        leergewicht: "450",
        zuladung: "1150",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1455",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 41",
        ref: "mv41",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F41.png?alt=media&token=f5e3f5d6-7004-48f7-8337-e8e7b2f55bd8",
        gewicht: "1600",
        motorraeder: "4",
        plattformmass: "1.8 x 4.07",
        anhaengerbreite: "2.3",
        leergewicht: "450",
        zuladung: "1150",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1455",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 42",
        ref: "mv42",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F42.png?alt=media&token=f28b94fc-2bc8-49f4-957f-f76ee3e26e87",
        gewicht: "1800",
        motorraeder: "6",
        plattformmass: "1.9 x 4.4",
        anhaengerbreite: "2.4",
        leergewicht: "500",
        zuladung: "1300",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1636",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 62",
        ref: "mv62",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F62.png?alt=media&token=0cedbeb5-3f7c-47c8-b232-ce0ab5f3997e",
        gewicht: "1300",
        motorraeder: "3",
        plattformmass: "1.8 x 3.0",
        anhaengerbreite: "1.8",
        leergewicht: "400",
        zuladung: "900",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 63",
        ref: "mv63",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F63.png?alt=media&token=1dfeb8d3-3b1d-4517-a61d-aa7ea30aa815",
        gewicht: "1300",
        motorraeder: "3",
        plattformmass: "1.8 x 3.0",
        anhaengerbreite: "1.8",
        leergewicht: "400",
        zuladung: "900",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 64",
        ref: "mv64",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2FMV64.jpg?alt=media&token=3274e09e-85fd-4490-9a81-12f96f19df57",
        gewicht: "1300",
        motorraeder: "3",
        plattformmass: "1.8 x 3.0",
        anhaengerbreite: "1.8",
        leergewicht: "350",
        zuladung: "950",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 65",
        ref: "mv65",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2F65.png?alt=media&token=8fb9ffb4-78ff-4ef7-b7bb-b5449485f60d",
        gewicht: "1300",
        motorraeder: "3",
        plattformmass: "1.8 x 3.0",
        anhaengerbreite: "1.8",
        leergewicht: "350",
        zuladung: "950",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "1185",
        auflaufbremse: "ja",
        fahrradtraeger: "ja",
      },
      {
        bezeichnung: "MV 116",
        ref: "mv116",
        bild: "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anh%C3%A4nger%2FMV116.png?alt=media&token=ba34157b-b294-461d-9cf2-5ef05d0e50a9",
        gewicht: "850",
        motorraeder: "2",
        plattformmass: "1.52 x 2.10",
        anhaengerbreite: "2.0",
        leergewicht: "270",
        zuladung: "580",
        geschwindigkeit: "100",
        leergewichtZugfahrzeug: "773",
        auflaufbremse: "ja",
        fahrradtraeger: "Auf Nachfrage",
      },
    ],
  }),
  mounted() {
    if (this.$route.hash != "") {
      this.scrollToTargetAdjusted(this.$route.hash.substring(1));
    }
  },
  components: { AnfangsBild },
  methods: {
    scrollToTargetAdjusted(id) {
      var element = document.getElementById(id);
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
