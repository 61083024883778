<template>
  <v-dialog v-model="dialog" width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <font-awesome-icon
          color="rgb(48, 66, 83, 1)"
          size="2x"
          icon="fa-solid fa-circle-info"
      /></v-btn>
      <div v-bind="attrs" v-on="on" class="ml-2">Unser Zubehör</div>
    </template>
    <v-card class="">
      <v-card-title>Unser Zubehör</v-card-title>
      <v-card-text>
        <v-container
          v-for="(item, index) in this.$parent.zubehörListe"
          :key="index"
        >
          <v-card-title>{{ item.cardTitel }}</v-card-title>
          <v-img
            :src="item.cardBild"
            :lazy-src="item.cardBild"
            :aspect-ratio="4 / 3"
          ></v-img>
          <v-card-text class="">{{ item.cardText }}</v-card-text>
          <v-card-text>{{ item.cardPreis }}</v-card-text>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>

<style></style>
