<template>
  <div>
    <AnfangsBild
      bild="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anfangsbilder%2FStandorte_Anfangsbild.jpg?alt=media&token=ea1f625a-a223-40d7-a038-dff4d3437c19"
      textErsteZeile="Abholung & "
      textZweiteZeile="Anfahrtsweg"
    />
    <v-container
      fluid
      style="max-width: 1365px"
      class="mt-lg-16 mt-8 px-10"
      :id="'Hillegossen'"
    >
      <div>
        <p class="text-md-h4 text-h5 font-weight-bold">Hillegossen:</p>
        <p class="text-lg-h6">Stargarder Str.28, 33699</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.5531794925496!2d8.605508816476602!3d51.99679717971841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba3e8466ee5315%3A0x9bf17ee01000418a!2sStargarder%20Str.%2028%2C%2033699%20Bielefeld!5e0!3m2!1sde!2sde!4v1652545428205!5m2!1sde!2sde"
          style="border: 0; width: 100%; height: 600px"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          class="d-none d-md-block"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.5531794925496!2d8.605508816476602!3d51.99679717971841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba3e8466ee5315%3A0x9bf17ee01000418a!2sStargarder%20Str.%2028%2C%2033699%20Bielefeld!5e0!3m2!1sde!2sde!4v1652545428205!5m2!1sde!2sde"
          style="border: 0; width: 100%; height: 400px"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          class="d-md-none"
        ></iframe>
      </div>
    </v-container>
    <v-container
      fluid
      style="max-width: 1365px"
      class="mt-lg-16 mt-8 px-10"
      :id="'Brake'"
      ><div>
        <p class="text-md-h4 text-h5 font-weight-bold">Brake:</p>
        <p class="text-lg-h6">Husumer Str. 112, 33729 Bielefeld</p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1336.993518363538!2d8.600035242060478!3d52.07869453609785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba16688fb7a2ff%3A0x47b51ce4fd8399f9!2sHusumer%20Str.%20112%2C%2033729%20Bielefeld!5e0!3m2!1sde!2sde!4v1652546112039!5m2!1sde!2sde"
          style="border: 0; width: 100%; height: 600px"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          class="d-none d-md-block"
        ></iframe>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1336.993518363538!2d8.600035242060478!3d52.07869453609785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba16688fb7a2ff%3A0x47b51ce4fd8399f9!2sHusumer%20Str.%20112%2C%2033729%20Bielefeld!5e0!3m2!1sde!2sde!4v1652546112039!5m2!1sde!2sde"
          style="border: 0; width: 100%; height: 400px"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          class="d-md-none"
        ></iframe>
      </div>
    </v-container>
  </div>
</template>

<script scoped>
import AnfangsBild from "@/components/AnfangsBild.vue";
export default {
  name: "StandorteView",
  metaInfo: {
    title: "Standorte",
  },
  mounted() {
    if (this.$route.hash != "") {
      this.scrollToTargetAdjusted(this.$route.hash.substring(1));
    }
  },
  methods: {
    scrollToTargetAdjusted(id) {
      var element = document.getElementById(id);
      var headerOffset = 60;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
  components: {
    AnfangsBild,
  },
};
</script>

<style></style>
