<template>
  <div>
    <AnfangsBild
      bild="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Anfangsbilder%2FPreise_Anfangsbild.jpg?alt=media&token=aafd557b-1c7f-40ed-a52c-b2b9f9253928"
      textErsteZeile="Preise & "
      textZweiteZeile="Anfrage"
    />

    <template v-if="showForm">
      <!-- Formular für Große Bildschirme-->
      <div class="px-4 d-none d-md-block">
        <div color="rgb(48, 66, 83, 0.65)" class="mx-auto mt-16">
          <v-stepper v-model="cardList" flat>
            <v-stepper-items>
              <v-stepper-content step="1" class="px-lg-0 mb-6 py-0">
                <!--Preisberechnungcard-->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onCheckWeiter)">
                    <v-card
                      class="mb-6 mx-auto pa-8"
                      color="rgb(48, 66, 83, 1)"
                      width="1365px"
                      ><p class="white--text text-h4 pt-5 pb-5 pl-10">
                        Preisberechnung
                      </p>
                      <v-row>
                        <v-col cols="8">
                          <v-container fluid>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-calendar"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >Mietzeitraum:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="formData.date"
                                  transition="scale-transition"
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <ValidationProvider
                                      v-slot="{ errors }"
                                      name="date"
                                      rules="required"
                                      ><v-text-field
                                        :error-messages="errors"
                                        v-model="formData.date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dark
                                        label="Bitte Start- und Enddatum wählen"
                                        class=""
                                      ></v-text-field>
                                    </ValidationProvider>
                                  </template>

                                  <v-date-picker
                                    v-model="formData.date"
                                    no-title
                                    scrollable
                                    range
                                    color="rgb(48, 66, 83, 1)"
                                    :first-day-of-week="1"
                                    locale="de"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      @click="
                                        $refs.menu.save(null);
                                        $refs.menuKlein.save(null);
                                        kostenBerechnung(null);
                                      "
                                    >
                                      Löschen
                                    </v-btn>
                                    <v-btn
                                      text
                                      @click="
                                        $refs.menu.save(formData.date);
                                        $refs.menuKlein.save(formData.date);
                                        kostenBerechnung(formData.date);
                                      "
                                    >
                                      Ok
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu></v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                lg="5"
                                cols="6"
                                style="height: 94px"
                                class="py-1"
                              >
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-motorcycle"
                                      inverse
                                      size="2x" /></v-col
                                  ><v-col class="ml-2"
                                    >Motorradanzahl:
                                    {{ formData.anzMotorrad }}</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1"
                                ><v-slider
                                  v-model="formData.anzMotorrad"
                                  @change="
                                    kostenBerechnung(formData.date);
                                    saveAnzMotorrad(formData.anzMotorrad);
                                  "
                                  min="1"
                                  max="6"
                                  thumb-label="always"
                                  class="text-body-1 pt-2"
                                  track-color="blue-grey lighten-2"
                                  thumb-color="#E0E0E0"
                                  color="white"
                                >
                                  <template v-slot:thumb-label="{ value }">
                                    <span class="black--text">{{ value }}</span>
                                  </template></v-slider
                                >
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-id-card"
                                      inverse
                                      size="2x" /></v-col
                                  ><v-col class="ml-2"
                                    >Führerschein:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="fuehrerschein"
                                  rules="required"
                                  ><v-select
                                    v-model="formData.fuehrerschein"
                                    :items="scheinListe"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    label="Bitte wählen"
                                    class="text-body-1"
                                  ></v-select>
                                </ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-location-dot"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >Wunschabholort:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <v-select
                                  v-model="formData.abholort"
                                  outlined
                                  dark
                                  :items="abholListe"
                                  label="Bitte wählen"
                                  class="white--text text-body-1"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-flag-checkered"
                                      inverse
                                      size="2x"
                                  /></v-col>
                                  <v-col class="ml-2">
                                    Zielort:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="zielort"
                                  rules="required|alpha_spaces"
                                >
                                  <v-text-field
                                    :error-messages="errors"
                                    v-model="formData.zielort"
                                    outlined
                                    dark
                                    class="text-body-1"
                                  ></v-text-field>
                                </ValidationProvider>
                              </v-col>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-plus"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >Zubehör:</v-col
                                  ></v-subheader
                                >
                              </v-col>

                              <v-col lg="7" cols="6" class="py-1">
                                <v-select
                                  outlined
                                  v-model="formData.zubehörSelected"
                                  :items="zubehörListe"
                                  item-text="titel"
                                  multiple
                                  dark
                                  class="text-body-1"
                                  @change="kostenBerechnung(formData.date)"
                                >
                                  <template #selection="{ item }">
                                    <v-chip
                                      light
                                      close
                                      @click:close="
                                        deleteChip(
                                          item.titel,
                                          formData.zubehörSelected
                                        );
                                        kostenBerechnung(formData.date);
                                      "
                                      >{{ item.titel }}</v-chip
                                    >
                                  </template>
                                  <template v-slot:item="{ item }">
                                    <v-menu
                                      offset-x
                                      :close-on-content-click="false"
                                      open-on-hover
                                      v-model="item.hoverItem"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <span
                                          v-on="on"
                                          v-bind="attrs"
                                          style="width: 350px"
                                          class="fill-height"
                                          >{{ item.titel }}
                                        </span>
                                      </template>
                                      <v-card width="350px">
                                        <v-card-title
                                          >{{ item.cardTitel }}
                                          <v-spacer></v-spacer
                                          ><v-btn
                                            icon
                                            @click="item.hoverItem = false"
                                          >
                                            <font-awesome-icon
                                              icon="fa-solid fa-xmark"
                                              size="2x"
                                              class=""
                                            /> </v-btn
                                        ></v-card-title>
                                        <v-img
                                          :src="item.cardBild"
                                          :lazy-src="item.cardBild"
                                          :aspect-ratio="4 / 3"
                                        ></v-img>
                                        <v-card-text class="">{{
                                          item.cardText
                                        }}</v-card-text>
                                        <v-card-text>{{
                                          item.cardPreis
                                        }}</v-card-text>
                                      </v-card>
                                    </v-menu>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                        <v-col class="white--text">
                          <v-container class="">
                            <p class="text-h6">
                              Der vorrauss. Mietpreis beträgt:
                            </p>
                            <p
                              class="preisAusgabe text-h4 font-weight-bold"
                              style="height: 80px"
                            ></p>
                            <p class="anhängerAusgabe"></p>
                            <p class="zubehörAusgabe"></p>
                            <p class="text-body-1">
                              Zusätzlich nehmen wir eine Kaution in Höhe von
                              150€ für den Anhänger bei der Abholung und für
                              konstenpflichtiges Zubehör jeweils 30€.
                            </p>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div
                      class="d-flex flex-row-reverse mx-auto"
                      style="max-width: 1365px"
                    >
                      <v-btn
                        dark
                        class="justify-right"
                        color="rgb(48, 66, 83, 1)"
                        type="checkWeiter"
                      >
                        Weiter
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-content step="2" class="px-lg-0 py-0 mb-6">
                <!--Mietanfragecard-->
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <v-card
                      class="mb-6 pa-8 mx-auto"
                      color="rgb(48, 66, 83, 1)"
                      width="1365px"
                      ><p class="white--text text-h4 pt-5 pb-5 pl-10">
                        Mietanfrage
                      </p>
                      <v-row>
                        <v-col cols="8">
                          <v-container fluid>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-calendar"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >Anrede:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <v-select
                                  outlined
                                  v-model="formData.anrede"
                                  dark
                                  :items="anredeListe"
                                  label="Bitte wählen"
                                  class="white--text text-body-1"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-user"
                                      inverse
                                      size="2x" /></v-col
                                  ><v-col class="ml-2"
                                    >Vor- und Nachname:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1"
                                ><ValidationProvider
                                  v-slot="{ errors }"
                                  name="name"
                                  rules="required|alpha_spaces"
                                  ><v-text-field
                                    v-model="formData.name"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field
                                ></ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-house"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >Straße und Hausnummer:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="straße"
                                  rules="required"
                                  ><v-text-field
                                    v-model="formData.straße"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field
                                ></ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-location-dot"
                                      inverse
                                      size="2x"
                                      class="" /></v-col
                                  ><v-col class="ml-2"
                                    >PLZ und Wohnort:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="2" cols="2" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="plz"
                                  rules="required|numeric|min:5|max:5"
                                  ><v-text-field
                                    v-model="formData.plz"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field></ValidationProvider
                              ></v-col>
                              <v-col lg="5" cols="4" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="wohnort"
                                  rules="required|alpha_spaces"
                                  ><v-text-field
                                    v-model="formData.wohnort"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field
                                ></ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-envelope"
                                      inverse
                                      size="2x" /></v-col
                                  ><v-col class="ml-2"
                                    >E-Mail-Adresse:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="email"
                                  rules="required|email"
                                  ><v-text-field
                                    v-model="formData.email"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field
                                ></ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-phone"
                                      inverse
                                      size="2x"
                                      class="ml-lg-2 ml-1" /></v-col
                                  ><v-col class="ml-2"
                                    >Handynummer:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="nummer"
                                  rules="required|numeric"
                                  ><v-text-field
                                    v-model="formData.nummer"
                                    :error-messages="errors"
                                    outlined
                                    dark
                                    class="white--text text-body-1"
                                  ></v-text-field
                                ></ValidationProvider>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-motorcycle"
                                      inverse
                                      size="2x"
                                  /></v-col>
                                  <v-col class="ml-2"
                                    >Motorradtypen:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="motorradtyp"
                                  rules="required"
                                  ><v-textarea
                                    v-model="formData.motorradtyp"
                                    :error-messages="errors"
                                    outlined
                                    label="Yamaha MT-125, Ducati Monster 821, etc."
                                    rows="1"
                                    dark
                                    class="white--text text-body-1"
                                  ></v-textarea
                                ></ValidationProvider>
                              </v-col>
                              <v-col lg="5" cols="6" class="py-1">
                                <v-subheader class="white--text text-body-1"
                                  ><v-col cols="3" class="text-center"
                                    ><font-awesome-icon
                                      icon="fa-solid fa-message"
                                      inverse
                                      size="2x" /></v-col
                                  ><v-col class="ml-2"
                                    >Nachricht:</v-col
                                  ></v-subheader
                                >
                              </v-col>
                              <v-col lg="7" cols="6" class="py-1">
                                <v-textarea
                                  v-model="formData.nachricht"
                                  outlined
                                  rows="2"
                                  dark
                                  class="white--text text-body-1"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                        <v-col class="white--text">
                          <div class="" style="height: 650px">
                            <p class="text-h6">
                              Der vorrauss. Mietpreis beträgt:
                            </p>
                            <p
                              class="preisAusgabe text-h4 font-weight-bold"
                              style="height: 80px"
                            ></p>
                            <p class="anhAusgabe"></p>
                            <p class="zubAusgabe"></p>

                            <p
                              v-if="formData.zubehörSelected.length == 0"
                              class=""
                            >
                              Ausgewähltes Zubehör: -
                            </p>
                            <p v-else class="mb-0">Ausgewähltes Zubehör:</p>
                            <p
                              class="ml-3 mb-0"
                              v-for="(item, index) in formData.zubehörSelected"
                              :key="index"
                            >
                              {{ item }}
                            </p>
                            <p class="text-body-1 mt-2">
                              Zusätzlich nehmen wir eine Kaution in Höhe von
                              150€ für den Anhänger bei der Abholung und für
                              konstenpflichtiges Zubehör jeweils 30€.
                            </p>
                          </div>
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="checkbox"
                            :rules="{ required: { allowFalse: false } }"
                          >
                            <v-checkbox
                              dark
                              v-model="formData.checkbox"
                              class=" "
                              :error-messages="errors"
                            >
                              <template v-slot:label>
                                <div>
                                  Ich habe die
                                  <a
                                    target="_blank"
                                    href="Datenschutz"
                                    @click.stop
                                    class="white--text"
                                  >
                                    Datenschutzerklärung
                                  </a>
                                  von MAV Bielefeld gelesen und verstanden. Ich
                                  stimme dieser zu.
                                </div>
                              </template>
                            </v-checkbox>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-card>
                    <div
                      class="mx-auto d-flex justify-space-between"
                      style="max-width: 1365px"
                    >
                      <v-btn text @click="cardList = 1" class="">
                        Zurück
                      </v-btn>
                      <v-btn
                        type="Submit"
                        dark
                        class=""
                        color="rgb(48, 66, 83, 1)"
                      >
                        Absenden
                      </v-btn>
                    </div>
                  </form>
                </ValidationObserver>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>
      <!-- Formular für Kleine Bildschirme-->
      <div class="px-4 pt-8 d-md-none">
        <template>
          <v-stepper v-model="cardList" vertical flat>
            <v-stepper-step
              :complete="cardList > 1"
              step="1"
              color="rgb(48, 66, 83, 1)"
            >
              Preisberechnung
              <small v-if="formData.date == null" class="mt-2"></small>
              <small v-else class="mt-2"
                ><span class="preisAusgabe font-weight-bold"> </span>
                <br /><span class="anhAusgabe"> </span><br /><span
                  class="zubAusgabe"
                >
                </span
                ><br />zusätzliche Kaution: 150€ + 30€/kostenpfl. Zubehör</small
              >
            </v-stepper-step>
            <v-stepper-content step="1" class="ml-sm-9 ml-0">
              <!--Preisberechnungscard klein-->
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onCheckWeiter)">
                  <v-card class="mb-6" color="rgb(48, 66, 83, 1)">
                    <v-row>
                      <v-container fluid>
                        <v-row class="pt-10">
                          <v-col sm="4" class="py-sm-3 py-0">
                            <v-subheader class="white--text">
                              <v-col sm="3" cols="1" class="text-center">
                                <font-awesome-icon
                                  icon="fa-solid fa-calendar"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block"
                                />
                                <font-awesome-icon
                                  icon="fa-solid fa-calendar"
                                  inverse
                                  class="fa-sm-1x d-sm-none"
                                /> </v-col
                              ><v-col class="ml-2">Mietzeitraum:</v-col>
                            </v-subheader>
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <v-menu
                              ref="menuKlein"
                              v-model="menuKlein"
                              :close-on-content-click="false"
                              :return-value.sync="formData.date"
                              transition="scale-transition"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <ValidationProvider
                                  v-slot="{ errors }"
                                  name="date"
                                  rules="required"
                                >
                                  <v-text-field
                                    v-model="formData.date"
                                    dense
                                    readonly
                                    :error-messages="errors"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    dark
                                    label="Bitte Start- und Enddatum wählen"
                                    class=""
                                  ></v-text-field>
                                </ValidationProvider>
                              </template>
                              <v-date-picker
                                v-model="formData.date"
                                no-title
                                scrollable
                                range
                                color="rgb(48, 66, 83, 1)"
                                :first-day-of-week="1"
                                locale="de"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  @click="
                                    $refs.menu.save(null);
                                    $refs.menuKlein.save(null);
                                    kostenBerechnung(null);
                                  "
                                >
                                  Löschen
                                </v-btn>
                                <v-btn
                                  text
                                  @click="
                                    $refs.menu.save(formData.date);
                                    $refs.menuKlein.save(formData.date);
                                    kostenBerechnung(formData.date);
                                  "
                                >
                                  Ok
                                </v-btn>
                              </v-date-picker>
                            </v-menu></v-col
                          >
                        </v-row>
                        <v-row>
                          <v-col sm="4" class="pt-sm-3 py-0">
                            <v-subheader class="white--text"
                              ><v-col sm="3" cols="1" class="text-center"
                                ><font-awesome-icon
                                  icon="fa-solid fa-motorcycle"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block" />
                                <font-awesome-icon
                                  icon="fa-solid fa-motorcycle"
                                  inverse
                                  class="fa-sm-1x d-sm-none" /></v-col
                              ><v-col class="ml-2"
                                >Motorradanzahl:
                                {{ formData.anzMotorrad }}</v-col
                              ></v-subheader
                            >
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <v-slider
                              v-model="formData.anzMotorrad"
                              dense
                              @change="
                                kostenBerechnung(formData.date);
                                saveAnzMotorrad(formData.anzMotorrad);
                              "
                              min="1"
                              max="6"
                              thumb-label
                              class="text-body-1 pt-1"
                              track-color="blue-grey lighten-2"
                              thumb-color="#E0E0E0"
                              color="white"
                            >
                              <template v-slot:thumb-label="{ value }">
                                <span class="black--text">{{ value }}</span>
                              </template></v-slider
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="4" class="pt-sm-3 py-0">
                            <v-subheader class="white--text"
                              ><v-col sm="3" cols="1" class="text-center"
                                ><font-awesome-icon
                                  icon="fa-solid fa-id-card"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block" />
                                <font-awesome-icon
                                  icon="fa-solid fa-id-card"
                                  inverse
                                  class="fa-sm-1x d-sm-none" /></v-col
                              ><v-col class="ml-2"
                                >Führerschein:</v-col
                              ></v-subheader
                            >
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="fuehrerschein"
                              rules="required"
                            >
                              <v-select
                                v-model="formData.fuehrerschein"
                                :items="scheinListe"
                                :error-messages="errors"
                                outlined
                                dense
                                dark
                                label="Bitte wählen"
                                class=""
                              ></v-select
                            ></ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="4" class="pt-sm-3 py-0">
                            <v-subheader class="white--text"
                              ><v-col sm="3" cols="1" class="text-center"
                                ><font-awesome-icon
                                  icon="fa-solid fa-location-dot"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block" />
                                <font-awesome-icon
                                  icon="fa-solid fa-location-dot"
                                  inverse
                                  class="fa-sm-1x d-sm-none" /></v-col
                              ><v-col class="ml-2"
                                >Wunschabholort:</v-col
                              ></v-subheader
                            >
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <v-select
                              v-model="formData.abholort"
                              outlined
                              dense
                              dark
                              :items="abholListe"
                              label="Bitte wählen"
                              class="white--text"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="4" class="pt-sm-3 py-0">
                            <v-subheader class="white--text"
                              ><v-col sm="3" cols="1" class="text-center"
                                ><font-awesome-icon
                                  icon="fa-solid fa-flag-checkered"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block" />
                                <font-awesome-icon
                                  icon="fa-solid fa-flag-checkered"
                                  inverse
                                  class="fa-sm-1x d-sm-none"
                              /></v-col>
                              <v-col class="ml-2"> Zielort:</v-col></v-subheader
                            >
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="zielort"
                              rules="required|alpha_spaces"
                            >
                              <v-text-field
                                v-model="formData.zielort"
                                :error-messages="errors"
                                outlined
                                dark
                                dense
                                class=""
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col sm="4" class="pt-sm-3 py-0">
                            <v-subheader class="white--text"
                              ><v-col sm="3" cols="1" class="text-center"
                                ><font-awesome-icon
                                  icon="fa-solid fa-plus"
                                  inverse
                                  class="fa-sm-2x d-none d-sm-block" />
                                <font-awesome-icon
                                  icon="fa-solid fa-plus"
                                  inverse
                                  class="fa-sm-1x d-sm-none" /></v-col
                              ><v-col class="ml-2">Zubehör:</v-col></v-subheader
                            >
                          </v-col>
                          <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                            <v-select
                              outlined
                              dense
                              v-model="formData.zubehörSelected"
                              :items="zubehörListe"
                              item-text="titel"
                              multiple
                              dark
                              class=""
                            >
                              <template #selection="{ item }">
                                <v-chip
                                  light
                                  small
                                  close
                                  @click:close="
                                    deleteChip(
                                      item.titel,
                                      formData.zubehörSelected
                                    );
                                    kostenBerechnung(formData.date);
                                  "
                                  >{{ item.titel }}</v-chip
                                >
                              </template>
                              <!-- Hover Bug für kleine Touchgeräte
                                <template v-slot:item="{ item }">
                                <v-menu
                                  offset-y
                                  :close-on-content-click="false"
                                  open-on-hover
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      v-on="on"
                                      v-bind="attrs"
                                      style="width: 300px"
                                      class="fill-height"
                                      >{{ item.titel }}
                                    </span>
                                  </template>

                                  <v-card width="300px">
                                    <v-card-title>{{
                                      item.cardTitel
                                    }}</v-card-title>
                                    <v-img
                                      :src="item.cardBild"
                                      :lazy-src="item.cardBild"
                                    ></v-img>
                                    <v-card-text class="">{{
                                      item.cardText
                                    }}</v-card-text>
                                    <v-card-text>{{
                                      item.cardPreis
                                    }}</v-card-text>
                                  </v-card>
                                </v-menu>
                              </template>
                              -->
                            </v-select>
                          </v-col>
                        </v-row>
                        <div class="d-flex justify-center">
                          <p class="mt-5 white--text text-body-1">
                            Vorr. Preis: <span class="preisAusgabe"></span>
                          </p>
                        </div>
                      </v-container> </v-row
                  ></v-card>
                  <v-btn dark color="rgb(48, 66, 83, 1)" type="checkWeiter">
                    Weiter
                  </v-btn>
                </form>
              </ValidationObserver>
            </v-stepper-content>

            <v-stepper-step
              :complete="cardList > 2"
              step="2"
              color="rgb(48, 66, 83, 1)"
            >
              Mietanfrage
            </v-stepper-step>
            <!--Mietanfragecard klein-->
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <v-stepper-content step="2" class="ml-sm-9 ml-0">
                  <v-card color="rgb(48, 66, 83, 1)" class="mb-6">
                    <v-container fluid>
                      <v-row class="pt-10">
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-calendar"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-calendar"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2">Anrede:</v-col></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <v-select
                            outlined
                            v-model="formData.anrede"
                            dense
                            dark
                            :items="anredeListe"
                            label="Bitte wählen"
                            class="white--text"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-user"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-user"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2"
                              >Vor- und Nachname:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0"
                          ><ValidationProvider
                            v-slot="{ errors }"
                            name="name"
                            rules="required|alpha_spaces"
                            ><v-text-field
                              outlined
                              v-model="formData.name"
                              :error-messages="errors"
                              dense
                              dark
                              class="white--text"
                            ></v-text-field
                          ></ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-house"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-house"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2"
                              >Straße und Hausnummer:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="straße"
                            rules="required"
                            ><v-text-field
                              outlined
                              v-model="formData.straße"
                              :error-messages="errors"
                              dense
                              dark
                              class="white--text"
                            ></v-text-field
                          ></ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-phone"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-phone"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2"
                              >PLZ und Wohnort:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <v-row>
                            <v-col sm="3" cols="4">
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="plz"
                                rules="required|numeric|min:5|max:5"
                                ><v-text-field
                                  outlined
                                  v-model="formData.plz"
                                  :error-messages="errors"
                                  dense
                                  dark
                                  class="white--text"
                                ></v-text-field
                              ></ValidationProvider>
                            </v-col>
                            <v-col>
                              <ValidationProvider
                                v-slot="{ errors }"
                                name="wohnort"
                                rules="required|alpha_spaces"
                                ><v-text-field
                                  outlined
                                  v-model="formData.wohnort"
                                  :error-messages="errors"
                                  dense
                                  dark
                                  class="white--text"
                                ></v-text-field
                              ></ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-envelope"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-envelope"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2"
                              >E-Mail-Adresse:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email"
                          >
                            <v-text-field
                              dense
                              v-model="formData.email"
                              :error-messages="errors"
                              outlined
                              dark
                              class="white--text"
                            ></v-text-field
                          ></ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-phone"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-phone"
                                inverse
                                class="fa-sm-1x d-sm-none" /></v-col
                            ><v-col class="ml-2"
                              >Handynummer:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="nummer"
                            rules="required|numeric"
                            ><v-text-field
                              outlined
                              v-model="formData.nummer"
                              :error-messages="errors"
                              dense
                              dark
                              class="white--text"
                            ></v-text-field
                          ></ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-motorcycle"
                                inverse
                                class="fa-sm-2x d-none d-sm-block" />
                              <font-awesome-icon
                                icon="fa-solid fa-motorcycle"
                                inverse
                                class="fa-sm-1x d-sm-none"
                            /></v-col>
                            <v-col class="ml-2"
                              >Motorradtypen:</v-col
                            ></v-subheader
                          >
                        </v-col>
                        <v-col sm="7" cols="10" class="mx-auto pt-sm-4 py-0">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="motorradtyp"
                            rules="required"
                            ><v-textarea
                              outlined
                              label="Yamaha MT-125, Ducati Monster 821, etc."
                              v-model="formData.motorradtyp"
                              :error-messages="errors"
                              rows="1"
                              dense
                              dark
                              class="white--text text-body-1"
                            ></v-textarea
                          ></ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col sm="4" class="pt-sm-3 py-0">
                          <v-subheader class="white--text"
                            ><v-col sm="3" cols="1" class="text-center"
                              ><font-awesome-icon
                                icon="fa-solid fa-message"
                                inverse
                                class="fa-sm-2x d-none d-sm-block"
                              />
                              <font-awesome-icon
                                icon="fa-solid fa-message"
                                inverse
                                class="fa-sm-1x d-sm-none"
                              /> </v-col
                            ><v-col class="ml-2">Nachricht:</v-col></v-subheader
                          >
                        </v-col>
                        <v-col
                          sm="7"
                          cols="10"
                          class="mx-auto pt-sm-4 py-0 pb-6"
                        >
                          <v-textarea
                            outlined
                            v-model="formData.nachricht"
                            rows="2"
                            dark
                            class="white--text"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="checkbox"
                        :rules="{ required: { allowFalse: false } }"
                      >
                        <v-checkbox
                          dark
                          v-model="formData.checkbox"
                          class="mx-5"
                          :error-messages="errors"
                        >
                          <template v-slot:label>
                            <div>
                              Ich habe die
                              <a
                                target="_blank"
                                href="Datenschutz"
                                @click.stop
                                lass="white--text"
                              >
                                Datenschutzerklärung
                              </a>
                              von MAV Bielefeld gelesen und verstanden. Ich
                              stimme dieser zu.
                            </div>
                          </template>
                        </v-checkbox></ValidationProvider
                      >
                    </v-container>
                  </v-card>
                  <v-btn type="Submit" dark class="" color="rgb(48, 66, 83, 1)">
                    Absenden
                  </v-btn>
                  <v-btn text @click="cardList = 1" class="ml-2">
                    Zurück
                  </v-btn>
                </v-stepper-content>
              </form>
            </ValidationObserver>
          </v-stepper>
        </template>
      </div>
    </template>
    <!-- Karte beim erfolgreichem Senden-->
    <template v-else>
      <div class="px-4 pt-8">
        <v-card
          class="mb-6 mx-auto pa-8"
          color="rgb(48, 66, 83, 1)"
          width="1365px"
          ><v-img
            contain
            src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Fmav_auto_anhaenger.jpg?alt=media&token=3956568d-08bd-4ac3-942c-8978198ab463"
            lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Fmav_auto_anhaenger.jpg?alt=media&token=3956568d-08bd-4ac3-942c-8978198ab463"
          ></v-img
          ><v-card-title class="white--text"
            ><div class="mx-auto">
              Vielen Dank für Ihre Anfrage!
            </div></v-card-title
          ></v-card
        >
      </div>
    </template>
    <v-row class="justify-center mx-auto mb-16" style="max-width: 900px">
      <v-col md="4" cols="7">
        <v-row class="align-center justify-center mt-md-0 mt-5">
          <PreiseDialog />
        </v-row>
      </v-col>
      <v-col md="4" cols="7">
        <v-row class="align-center justify-center mt-md-0 mt-5">
          <ZubehoerDialog />
        </v-row>
      </v-col>
    </v-row>
    <BildmitText
      :bild="imageTextComponent.imageSrc"
      :titel="imageTextComponent.titel"
      :text="imageTextComponent.text"
      :align="imageTextComponent.align"
      :flipped="imageTextComponent.flipped"
    />
    <vue-recaptcha
      ref="invisibleRecaptcha"
      @verify="onVerify"
      @expired="onExpired"
      size="invisible"
      sitekey="6LcD610iAAAAALyL5xojyr-yhF-9QUZXjnAXCjdW"
    >
    </vue-recaptcha>
  </div>
</template>

<script>
import AnfangsBild from "@/components/AnfangsBild.vue";
import BildmitText from "@/components/BildmitText.vue";
import PreiseDialog from "@/components/PreiseDialog.vue";
import ZubehoerDialog from "@/components/ZubehörDialog.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { db } from "../firebase/firebaseInit";
import { doc, setDoc } from "firebase/firestore";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  alpha_spaces,
  min,
  max,
  numeric,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

const preiseAnhanger = new Array(6);

for (var i = 0; i < preiseAnhanger.length; i++) {
  preiseAnhanger[i] = new Array(6);
}

preiseAnhanger[0][0] = 25;
preiseAnhanger[0][1] = 40;
preiseAnhanger[0][2] = 55;
preiseAnhanger[0][3] = 85;
preiseAnhanger[0][4] = 105;
preiseAnhanger[0][5] = 165;

preiseAnhanger[1][0] = 25;
preiseAnhanger[1][1] = 45;
preiseAnhanger[1][2] = 65;
preiseAnhanger[1][3] = 95;
preiseAnhanger[1][4] = 130;
preiseAnhanger[1][5] = 200;

preiseAnhanger[2][0] = 25;
preiseAnhanger[2][1] = 45;
preiseAnhanger[2][2] = 65;
preiseAnhanger[2][3] = 95;
preiseAnhanger[2][4] = 130;
preiseAnhanger[2][5] = 200;

preiseAnhanger[3][0] = 45;
preiseAnhanger[3][1] = 70;
preiseAnhanger[3][2] = 90;
preiseAnhanger[3][3] = 120;
preiseAnhanger[3][4] = 180;
preiseAnhanger[3][5] = 260;

preiseAnhanger[4][0] = 55;
preiseAnhanger[4][1] = 90;
preiseAnhanger[4][2] = 110;
preiseAnhanger[4][3] = 170;
preiseAnhanger[4][4] = 220;
preiseAnhanger[4][5] = 310;

preiseAnhanger[5][0] = 60;
preiseAnhanger[5][1] = 100;
preiseAnhanger[5][2] = 130;
preiseAnhanger[5][3] = 190;
preiseAnhanger[5][4] = 260;
preiseAnhanger[5][5] = 350;

let mietPreisAnhanger = 0;
let mietPreisZubehor = 0;

let kautionAnhanger = 150;

extend("required", {
  ...required,
  message: "Pflichtfeld",
});

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Nur Buchstaben",
});

extend("email", {
  ...email,
  message: "Ungültige Email",
});

extend("min", {
  ...min,
  message: "Ungültige Nummer",
});

extend("max", {
  ...max,
  message: "Ungültige Nummer",
});

extend("numeric", {
  ...numeric,
  message: "Ungültige Nummer",
});

export default {
  name: "PreiseAnfrageView",
  metaInfo: {
    title: "Preise & Anfrage",
  },
  data() {
    return {
      formData: {
        date: null,
        isoDate: null,
        anzMotorrad: null,
        fuehrerschein: null,
        abholort: null,
        zielort: null,
        anrede: null,
        name: null,
        straße: null,
        plz: null,
        wohnort: null,
        email: null,
        nummer: null,
        motorradtyp: null,
        nachricht: null,
        checkbox: false,
        zubehörSelected: [],
        preisAnhanger: null,
        preisZubehor: null,
      },
      cardList: 1,
      DatumOkay: false,
      menu: false,
      menuKlein: false,
      showForm: true,
      scheinListe: [
        "Führerschein B (3.5t)",
        "Führerschein BE (4.25t)",
        "Führerschein Klasse 3 (7.5t)",
      ],
      abholListe: [" Bielefeld - Hillegossen", " Bielefeld - Brake"],
      anredeListe: ["Herr", "Frau", ""],
      /*scheinListe: [
      "Führerschein B, zulässiges Gesamtgewicht 3.5t",
      "Führerschein BE (mit B96 Zusatz), zulässiges Gesamtgewicht 4.25t",
      "Führerschein Klasse 3, zulässiges Gesamtgewicht 7.5t",
    ],*/
      zubehörListe: [
        {
          titel: "Lenkradgurt",
          cardTitel: "Lenkradgurt",
          cardText:
            "Unsere Lenkradgurte dienen zur zusätzlichen Stabilität des Motorrades. Sie sind keine Hauptbefestigung und können dazu gebucht werden.",
          cardPreis: "Kostenlos",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Flenkradgurt.jpg?alt=media&token=f04c4c66-f301-43ad-9f37-c32a2e4a9668",
        },
        {
          titel: "Hinterradbefestigung",
          cardTitel: "Hinterradbefestigung",
          cardText:
            "Wenn keine Befestigung am Rahmen möglich ist, kommt unsere Hinterradbefestigung zum Einsatz. Die Hinterradbefestigung entspricht nicht der EN 12195.",
          cardPreis: "Kostenlos",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Fhinterradbefestigung.jpg?alt=media&token=5ee49e49-5eb7-4cf7-8aee-abbe186f4aa7",
        },
        {
          titel: "Spanngurte",
          cardTitel: "Spanngurte",
          cardText:
            "Für die bestmögliche Verzurrung stellen wir Euch Spanngurte bereit. Sie werden regelmäßig überprüft. Unsere Zurrgurte entsprechen der DIN EN 12195-2.",
          cardPreis: "Kostenlos",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Fspanngurte.jpg?alt=media&token=a1f91bb6-807a-44c7-b856-6b71c7e30524",
        },
        {
          titel: "Staubox",
          cardTitel: "Staubox",
          cardText:
            "Für weiteren Lagerungsmöglichkeiten bieten wir eine Staubox an, die auf den Anhänger befestigt werden kann.",
          cardPreis: "1-7 Tage 10,00€, ab 7 Tagen +1,00€/Tag",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Fstaubox.jpg?alt=media&token=23f3a249-63cc-4950-a9f0-6b5aeb9291cb",
        },
        {
          titel: "Wasserdichtes Fass",
          cardTitel: "Wasserdichtes Fass",
          cardText:
            "In diesem Fass ist es möglich die Motorradkleidung zu lagern. Das Fass ist aus Kunststoff und wasserdicht.",
          cardPreis:
            "1-3 Tage 10,00€, ab 4 Tagen +1,25€/Tag, ab 7 Tagen +1,50€/Tag",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Ffass.jpg?alt=media&token=bf929c27-3ded-4983-b88f-d99072c68d71",
        },
        {
          titel: "2er Fahrradträger",
          cardTitel: "2er Fahrradträger",
          cardText:
            "Um im Urlaub flexibel zwischen Fahrrad und Motorrad zu wechseln, ist dieser Fahrradträger gut geeignet. Er lässt sich auf den Anhänger oder das Auto montieren.",
          cardPreis:
            "Bis 2 Tagen +3,50€/Tag, ab 2 Tagen +2,50€/Tag, ab 7 Tagen +2,00€/Tag",
          cardBild:
            "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Zubeh%C3%B6r%2Ffahrradtr%C3%A4ger_2.jpg?alt=media&token=d0458bcc-c389-4316-a8a3-26f6a43f479b",
        },
      ],
      imageTextComponent: {
        titel: "Unser Buchungstipp",
        text: "Entspannt in den Urlaub zu fahren bedeutet auch, dass man die Motorräder in Ruhe verzurren kann. Aus Erfahrung können wir Euch empfehlen, die Motorräder einen Tag bevor Ihr fahrt in Ruhe zu verzurren, damit Ihr nicht in Zeitnot kommt. Es kam schon öfter vor, dass am gleichen Tag an dem man losfahren wollte, die Maschinen verladen hat. Kommt dann etwas unvorhergesehenes, hat man kaum noch Zeit zu handeln, und der Urlaub beginnt mit Stress. Deswegen empfehlen wir Euch die Motorräder einen Tag vor Antritt des Urlaubes zu verzurren. Bitte beachtet bei der Buchungsanfrage, dass die Abholung der Anhänger nach Vereinbarung bekannt gegeben werden. Die Rückgabe der Anhänger muss bis 20:00 Uhr erfolgen.",
        imageSrc:
          "https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2Fbuchungstipp.jpg?alt=media&token=98499592-ff15-4108-95e4-91052deb2880",
        align: "start",
        flipped: false,
      },
    };
  },
  computed: {
    form() {
      return {
        date: this.formData.date,
        isoDate: this.formData.isoDate,
        anzMotorrad: this.formData.anzMotorrad,
        fuehrerschein: this.formData.fuehrerschein,
        abholort: this.formData.abholort,
        zielort: this.formData.zielort,
        anrede: this.formData.anrede,
        name: this.formData.name,
        straße: this.formData.straße,
        plz: this.formData.plz,
        wohnort: this.formData.wohnort,
        email: this.formData.email,
        nummer: this.formData.nummer,
        motorradtyp: this.formData.motorradtyp,
        nachricht: this.formData.nachricht,
        checkbox: this.formData.checkbox,
        zubehörSelected: this.formData.zubehörSelected,
        preisAnhanger: this.formData.preisAnhanger,
        preisZubehor: this.formData.preisZubehor,
      };
    },
  },

  methods: {
    deleteChip(titel, zubehörSelected) {
      for (let i = 0; i < zubehörSelected.length; i++) {
        if (zubehörSelected[i] == titel) {
          zubehörSelected.splice(i, 1);
        }
      }
    },
    kostenBerechnungZubehor(tage) {
      //const zubehor = this.zubehörSelected.titel;
      for (let i = 0; i < this.formData.zubehörSelected.length; i++) {
        switch (this.formData.zubehörSelected[i]) {
          case "Staubox":
            if (tage > 0 && tage < 4) {
              mietPreisZubehor += 10;
            } else if (tage > 7) {
              mietPreisZubehor += 10 + (tage - 7) * 1;
            }
            break;
          case "Wasserdichtes Fass":
            if (tage > 0 && tage < 4) {
              mietPreisZubehor += 10;
            } else if (tage > 3 && tage < 8) {
              mietPreisZubehor += 10 + (tage - 3) * 1.25;
            } else if (tage > 7) {
              mietPreisZubehor += 15 + (tage - 7) * 1.5;
            }
            break;
          case "2er Fahrradträger":
            if (tage > 0 && tage < 3) {
              mietPreisZubehor = tage * 3.5;
            } else if (tage > 2 && tage < 8) {
              mietPreisZubehor += 10 + (tage - 3) * 2.5;
            } else if (tage > 7) {
              mietPreisZubehor += 15 + (tage - 7) * 2;
            }
            break;
          default:
            break;
        }
      }
    },
    kostenBerechnung(date) {
      mietPreisAnhanger = 0;
      mietPreisZubehor = 0;

      kautionAnhanger = 150;

      var outputMietpreis = document.getElementsByClassName("preisAusgabe");
      if (date == null) {
        for (var iMPNull = 0; iMPNull < outputMietpreis.length; iMPNull++) {
          outputMietpreis[iMPNull].innerHTML = "";
        }
      } else {
        try {
          if (date.length > 1) {
            var datum1 = new Date(date[0]);
            var datum2 = new Date(date[1]);
            if (datum1.getTime() > datum2.getTime()) {
              var mietStart = new Date(date[1]);
              var mietEnde = new Date(date[0]);
            } else {
              mietStart = new Date(date[0]);
              mietEnde = new Date(date[1]);
            }
          } else {
            mietStart = new Date(date[0]);
            mietEnde = new Date(date[0]);
          }
          var mietStartMilli = mietStart.getTime();
          var mietEndeMilli = mietEnde.getTime();
          var anzahlTage =
            (mietEndeMilli - mietStartMilli) / 1000 / 3600 / 24 + 1;
        } catch (err) {
          if (err instanceof TypeError) {
            for (var k = 0; k < outputMietpreis.length; k++) {
              outputMietpreis[k].innerHTML = "Bitte gültiges Datum eingeben!";
              this.DatumOkay = false;
            }
          } else {
            for (var t = 0; t < outputMietpreis.length; t++) {
              outputMietpreis[t].innerHTML = "Unbekannter Fehler!";
            }
          }
        }
        var heute = new Date();
        var anzahlMtr = this.formData.anzMotorrad;
        if (mietStartMilli < heute.getTime()) {
          for (var index1 = 0; index1 < outputMietpreis.length; index1++) {
            outputMietpreis[index1].innerHTML = "Startdatum bitte prüfen!";
            this.DatumOkay = false;
          }
        } else {
          if (anzahlTage > 14) {
            for (var index = 0; index < outputMietpreis.length; index++) {
              outputMietpreis[index].innerHTML = "Preis auf Anfrage";
              this.DatumOkay = true;
            }
          } else {
            mietPreisAnhanger = this.kostenBerechnungAnhanger(
              anzahlMtr,
              anzahlTage
            );

            if (
              mietStart.getTime() - heute.getTime() >= 7776000000 &&
              anzahlMtr > 1
            ) {
              if (anzahlTage > 10 && anzahlTage < 15) {
                mietPreisAnhanger *= 0.89;
              }
            }

            this.kostenBerechnungZubehor(anzahlTage);

            mietPreisZubehor = Math.round(mietPreisZubehor);
            mietPreisAnhanger = Math.round(mietPreisAnhanger);
            kautionAnhanger = Math.round(kautionAnhanger);
            var outputAnhpreis = document.getElementsByClassName("anhAusgabe");
            var outputZubpreis = document.getElementsByClassName("zubAusgabe");

            this.formData.preisAnhanger = mietPreisAnhanger;
            this.formData.preisZubehor = mietPreisZubehor;

            this.DatumOkay = true;

            for (var iMP = 0; iMP < outputMietpreis.length; iMP++) {
              outputMietpreis[iMP].innerHTML =
                mietPreisAnhanger + mietPreisZubehor + "€ ";
            }
            for (var iAP = 0; iAP < outputAnhpreis.length; iAP++) {
              outputAnhpreis[iAP].innerHTML =
                "Anhänger: " + mietPreisAnhanger + "€ ";
            }
            for (var iZP = 0; iZP < outputZubpreis.length; iZP++) {
              outputZubpreis[iZP].innerHTML =
                "Zubehör: " + mietPreisZubehor + "€ ";
            }
          }
        }
      }
    },

    kostenBerechnungAnhanger(anzahlMtr, anzahlTage) {
      anzahlMtr = Number(anzahlMtr);
      anzahlTage = Number(anzahlTage);

      var mietPreisAnhanger = 0;

      switch (true) {
        case anzahlTage == 1:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][0];
          break;

        case anzahlTage == 2:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][1];
          break;

        case anzahlTage == 3:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][2];
          break;

        case anzahlTage == 4:
          mietPreisAnhanger += (preiseAnhanger[anzahlMtr - 1][3] / 5) * 4;
          break;

        case anzahlTage == 5:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][3];
          break;

        case anzahlTage == 6:
          mietPreisAnhanger += (preiseAnhanger[anzahlMtr - 1][4] / 7) * 6;
          break;

        case anzahlTage >= 7 && anzahlTage < 14:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][4];
          switch (anzahlMtr) {
            case 1:
              mietPreisAnhanger += (anzahlTage - 7) * 8;
              break;

            case 2:
              mietPreisAnhanger += (anzahlTage - 7) * 10;
              break;

            case 3:
              mietPreisAnhanger += (anzahlTage - 7) * 10;
              break;

            case 4:
              mietPreisAnhanger += (anzahlTage - 7) * 13;
              break;

            case 5:
              mietPreisAnhanger += (anzahlTage - 7) * 15;
              break;

            case 6:
              mietPreisAnhanger += (anzahlTage - 7) * 15;
              break;
          }
          break;

        case anzahlTage == 14:
          mietPreisAnhanger += preiseAnhanger[anzahlMtr - 1][5];
          break;
      }

      return mietPreisAnhanger;
    },
    saveAnzMotorrad(anzahl) {
      this.formData.anzMotorrad = anzahl;
    },
    async onCheckWeiter() {
      if (this.DatumOkay) {
        this.cardList = 2;
      }
    },
    onVerify: async function () {
      //response
      //console.log("Verify: " + response);
      var t = new Date();
      var z = t.getTimezoneOffset() * 60 * 1000;
      var tLocal = t - z;
      tLocal = new Date(tLocal);
      var iso = tLocal.toISOString();
      iso = iso.slice(0, 19);
      iso = iso.replace("T", " ");
      this.formData.isoDate = iso;
      await setDoc(doc(db, "Anfragen", iso + " " + this.formData.name), {
        formData: this.formData,
      })
        .then(() => {
          console.log("abgesendet");
          this.showForm = false;
        })
        .catch((error) => console.log(error));
    },
    onExpired: function () {
      console.log("Recaptcha Expired");
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    onError: function (e) {
      console.log(e);
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
    },
  },
  components: {
    AnfangsBild,
    BildmitText,
    PreiseDialog,
    ZubehoerDialog,
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
};
</script>

<style></style>
